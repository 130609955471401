import { useEffect, memo, useContext } from 'react';
import { Card, Grid, Stack, Box, Typography, Divider } from '@mui/material';
import MDBox from 'components/MDBox';
import ViewToggle from 'layouts/purchases/pages/allLeads/layout/Header/Components/ViewToggle.js';
import ViewSwitch from 'layouts/purchases/pages/allLeads/layout/Header/Components/ViewSwitch.js';
import Search from 'layouts/purchases/pages/allLeads/layout/Header/Components/Search.js';
import Aaro from 'assets/images/ahsaplar2.png';
import MDAvatar from 'components/MDAvatar';
import { isMobile } from 'helpers/smallCodes';

const Header = memo(function HeaderPre({ children }) {
    useEffect(() => console.log('Header Rendered'));

    return (
        <MDBox position="relative" mb={5}>
            <HeaderGradiant />
            <Card
                sx={{
                    position: 'relative',
                    mt: -8,
                    mx: 3,
                    py: 2,
                    px: 2,
                }}
            >
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={6} lg={9} style={{ flexGrow: 1 }}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={isMobile() ? 'center' : 'flex-start'}
                            height="100%"
                            mt={0.5}
                            lineHeight={1}
                        >
                            <MDAvatar src={Aaro} alt="profile-image" size="xl" shadow="sm" variant="square" />
                            <Box ml={2} height="100%" mt={0.5} lineHeight={1}>
                                <Typography variant="h3" fontWeight="medium" mb={1}>
                                    {isMobile() ? 'Siparişler' : 'Aldığımız Siparişler'}
                                </Typography>
                                <Stack spacing={1} direction="row" alignItems="center">
                                    <ViewToggle />
                                    {!isMobile() && (
                                        <>
                                            <Divider orientation="vertical" flexItem />
                                            <ViewSwitch />
                                        </>
                                    )}
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} alignItems="center">
                        <MDBox display="flex" justifyContent="flex-end" width="100%" alignItems="center">
                            {!isMobile() && <Search />}
                        </MDBox>
                    </Grid>
                </Grid>
                {children}
            </Card>
        </MDBox>
    );
    function HeaderGradiant() {
        return (
            <MDBox
                display="flex"
                alignItems="center"
                position="relative"
                minHeight="8rem"
                borderRadius="xl"
                sx={{
                    background: 'linear-gradient(to right, #131823, #2C2F3A)', // #131823 tonuna uyumlu koyu gradyan
                    backgroundSize: 'cover',
                    backgroundPosition: '50%',
                    overflow: 'hidden',
                }}
            />
        );
    }
});

export default Header;
