import { memo, useEffect } from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useQuery } from 'react-query';
import DefaultLineChart from 'examples/Charts/LineCharts/DefaultLineChart';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { getStockMovements } from 'services/outside/aaro/stocks.js';

const fetchStockMovements = async (query) => {
    return await getStockMovements(query);
};

const createData = (data) => {
    const formattedValue = data.map((item) => ({
        value: item.Miktar,
        day: dateToYYYYMMDD(item.DgsTar),
    }));

    return Object.values(
        formattedValue.reduce((r, o) => {
            r[o.day] = r[o.day] || { day: o.day, value: 0 };
            r[o.day].value += +o.value;
            return r;
        }, {})
    );
};

const dateToYYYYMMDD = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

function StockMovements({ branchName, branchID, productID, setWhoBuys }) {
    const { height } = useWindowDimensions();
    const TipID = 10005; // Satış Faturası

    const { data, isLoading, error, isFetching } = useQuery(
        ['fetchAllStockMovements', productID, branchID, TipID, '2023-01-01T00:00:00'],
        () =>
            fetchStockMovements({
                StokID: productID,
                SubeID: branchID,
                TipID,
                TarihBas: '2023-01-01T00:00:00',
            }),
        { refetchOnWindowFocus: false, staleTime: 60000, cacheTime: 144000 }
    );

    useEffect(() => {
        if (data) setWhoBuys(Math.random());
    }, [data, setWhoBuys]);

    if (isLoading) return <div>Geçmiş Satışlar Yükleniyor...</div>;
    if (error) return <div>Error...{JSON.stringify(error)}</div>;
    if (isFetching) return <div>Geçmiş Satışlar Tekrar Çekiliyor...</div>;

    const chartData = createData(data);

    return (
        <MDBox
            sx={{ height: height / 3 + 100 }}
            bgColor="white"
            variant="gradient"
            borderRadius="lg"
            shadow="lg"
            opacity={1}
        >
            <MDTypography variant="h6" color="dark" verticalAlign="middle" align="center">
                2023 itibariyle yapılan satış miktarları: {branchName.toLocaleUpperCase()}
            </MDTypography>
            {data && (
                <DefaultLineChart
                    chart={{
                        title: 'Satış Miktarları',
                        labels: chartData.map((item) => item.day),
                        datasets: [
                            {
                                label: 'Satış Miktarı',
                                color: 'info',
                                data: chartData.map((item) => item.value),
                            },
                        ],
                    }}
                />
            )}
        </MDBox>
    );
}

export default memo(StockMovements);
