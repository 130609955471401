import React, { useState, useEffect, memo } from 'react';
import { useQuery } from 'react-query';
// import PeopleCardFooter from '@mui-treasury/components/cardFooter/people';

import AddResponsible from './AddResponsible';
import { getProject } from 'services/api/project';
import { getUsers } from 'services/api/user';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// @mui material components

import Tooltip from '@mui/material/Tooltip';

// Material Dashboard 2 PRO React components
import MDAvatar from 'components/MDAvatar';
import AaroLoadingScreen from 'components/Loading';

// Image

import team4 from 'assets/images/team-4.jpg';

const fetchProject = async (id) => {
    const response = await getProject(id)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};

const fetchUsers = async (id) => {
    const response = await getUsers(id)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};

const ProjectResponsibles = (props) => {
    const avatarStyles = {
        border: ({ borders: { borderWidth }, palette: { white } }) => `${borderWidth[2]} solid ${white.main}`,
        cursor: 'pointer',
        position: 'relative',
        ml: -1.5,

        '&:hover, &:focus': {
            zIndex: '10',
        },
    };
    const { projectID, showAddButton = true, avatarSize = 'sm', additionalStyle = null } = props;

    const { data, isLoading, error } = useQuery(['fetchProject', projectID], () => fetchProject(projectID), {
        staleTime: 600000,
    });

    const allUsers = useQuery('fetchUsers', () => fetchUsers(), {
        staleTime: 600000,
    });

    if (isLoading) return <AaroLoadingScreen />;
    if (error) return 'Bir Hata Oluştu: ' + error.message;
    if (allUsers.isLoading) return <AaroLoadingScreen />;
    if (allUsers.error) return 'Bir Hata Oluştu: ' + allUsers.error;

    const responsibles = data?.responsibles || [];
    if (!Array.isArray(responsibles)) return null;

    const uniqueResponsibles = responsibles.reduce((acc, current) => {
        const currentItem =
            typeof current === 'string' ? { user: allUsers.data?.find((user) => user._id === current) } : current;

        if (currentItem?.user && !acc.find((item) => item?.user?._id === currentItem.user._id)) {
            acc.push(currentItem);
        }
        return acc;
    }, []);

    return (
        <MDBox display="flex" flexDirection="row" justifyContent="space-evenly">
            {uniqueResponsibles
                .filter((el) => el?.user && typeof el.user === 'object')
                .map((el) => (
                    <Tooltip
                        key={el.user._id || `user-${Math.random()}`}
                        title={el.user.name || 'İsimsiz Kullanıcı'}
                        placement="top"
                    >
                        <MDAvatar
                            src={el.user.profilePicture || team4}
                            alt={el.user.name || 'İsimsiz Kullanıcı'}
                            size={avatarSize}
                            sx={additionalStyle ? additionalStyle : avatarStyles}
                        />
                    </Tooltip>
                ))}
            {showAddButton && (
                <MDBox pl={1}>
                    <AddResponsible
                        allResponsibles={uniqueResponsibles.map((el) => el.user).filter(Boolean)}
                        projectID={projectID}
                        users={allUsers.data || []}
                    />
                </MDBox>
            )}
        </MDBox>
    );
};

export default memo(ProjectResponsibles);
