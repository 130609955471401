// Constants.js

// url: {
// 	API_URL: 'https://kahramanlar.herokuapp.com',
// 	AARO_BASE: 'https://erp2.aaro.com.tr',
// 	AARO_CARI_KALEM: 'https://erp2.aaro.com.tr/Cari/Kalem',
// 	ENV_URL: 'https://kahramanlar.dev',
// },

const prod = {
    random: {
        ANONYMOUS_USER_IMAGE:
            'https://e7.pngegg.com/pngimages/347/642/png-clipart-facebook-social-media-councillor-okehampton-anonymous-silhouette-anonymous-thumbnail.png',
    },
    url: {
        API_URL: 'https://kahramanlar.herokuapp.com',
        AARO_BASE: 'https://erp2.aaro.com.tr',
        AARO_CARI_KALEM: 'https://erp2.aaro.com.tr/Cari/Kalem',
        ENV_URL: 'https://kahramanlar.dev',
        DOMAIN: 'kahramanlar.dev',
    },
    images: {
        uKasa: 'https://www.kapim.com.tr/wp-content/uploads/2021/12/MDF-U-Kasa.png',
        kaplamaliKasa: 'https://www.kapim.com.tr/wp-content/uploads/2021/12/Alpi-Ceviz.png',
        uPervaz: 'https://market.kahramanlar.com.tr/wp-content/uploads/2022/01/pervaz-u.png',
        kaplamaliPervaz: 'https://www.kapim.com.tr/wp-content/uploads/2021/12/Alpi-Ceviz-6.png',
        kanat: 'https://www.kapim.com.tr/wp-content/uploads/2021/09/Yeni-Model-4-Beyaz.png',
        boya: 'https://pngimg.com/uploads/box/box_PNG92.png',
        offerBackground:
            'https://kahramanlar.s3.amazonaws.com/projects/L%C3%9CY%20ORM.%C3%9CRN.LTD.%20%C5%9ET%C4%B0./L%C3%BCy%20500%20Kap%C4%B1/ky8ylfb6-kahramanlar-offer-background.png',
    },
    fixedID: {
        PROJECT_STATUS_TAG_GROUP_ID: '61532750ad0b34001891eaf5',
        DEMAND_STATUS_TAG_GROUP_ID: '615df4ce247a9f00180f62bf',
        PARAMS_CATEGORY_GROUP_ID: '6234c447b68c970018496ae2',
        CUSTOMER_PROFILE_GROUP_ID: '6262c9aad4d6c000169bf237',
        CUSTOMER_TYPE_GROUP_ID: '6262c99bd4d6c000169bf236',
        CUSTOMER_INTERESTS_GROUP_ID: '6262c984d4d6c000169bf235',
        CUSTOMER_SOURCE_GROUP_ID: '6262c8f4d4d6c000169bf232',
        PROJECT_LOSE_GROUP_ID: '6351296f1834400016e7007b',
        PROJECT_WIN_GROUP_ID: '6351295b1834400016e7007a',
        PROJECT_SOURCE_GROUP_ID: '635129911834400016e7007c',
        PROJECT_WINNER_GROUP_ID: '6351293f1834400016e70079',
        PROJECT_TAGS_GROUP_ID: '6756d1683df3c6001508b6d2',
        LEAD_CATEGORY_GROUP_ID: '637a4a93d4658e00168425a4',
        DEMAND_TEMPLATE_CATEGORY_ID: '64d0a46dcb33080014d439e0',
        DEMAND_TEMPLATE_PRODUCT_ID: '64d0a454cb33080014d439dd',
    },

    customer: {
        potentialID: '6262c9cfd4d6c000169bf238',
    },
    statusID: {
        uretiliyor: '61ab4c8bbfc062001877a7e1',
        uretildi: '61ab4df2bfc062001877a830',
        kaybedildi: '61532a4a5840090018a362fc',
        teklifGonderildi: '61532a235840090018a362fb',
        kazanildi: '615329cd5840090018a362f9',
        girdiBekliyor: '62028e68fa051500187ab1f2',
    },
    demandStatusID: {
        fiyatVerildi: '615df559e278a90018959101',
        duzeltilmeli: '615df56de278a90018959102',
        revizeBekleniyor: '615df5c3e278a90018959103',
        fiyatBekleniyor: '615df524e278a900189590ff',
    },
    pusher: {
        id: 'bff6464292c121f051a1',
    },
    google: {
        rootFolderID: '1QdwKGXLhfM92QkmT7wwFs2CflOAckPzX',
        priceListFolderName: '2024-08-11',
        googleSheetID: '1dtoLSlVujTAqqQAIs19DqwlmsHskXD0jSC0HMxPBPUA',
        sheetPageIDs: {
            kasa: 1972425608,
            kanat: 1777600013,
            pervaz: 1571720213,
            uKasa: 1180190356,
            uPervaz: 237928996,
            diger: 1285798873,
        },
        userOfferFolders: {
            SAMIL: '1Y8b1Xwp5z9aMFLtyLF5NVU_FdrBfKl_S',
            TOLGA: '1eqSzMaSDU9s_OahPD1NE-p-hicBQIABR',
            SGOLLU: '1b3oCWlwOvRGmXtO1NzEZCg66ofNREcdA',
            SERHATKAHRAMAN: '14bobwkXNm1DLutW6zqJsnAxrIi1ooh2J',
            SARIKDOGAN: '13uz3oOk1oeNkPz3LKRY9SmVazpks75AZ',
            BCINGI: '1U5p65b0Jl3mejSqh9nXpzj1R48MxwN0S',
            BILALK: '1PvUArS5FjsP_qfEjslxFCal6-JeYx2TG',
            ARIFBILICI: '1ReAywXANIEIO3raB-kcZ-PkjMhIaHpK9',
            ALIKEMALK: '1m7b2VVmrIqt0xx0LAubrL0JMcfFBPRlQ',
            AYILMAZ: '1IlJMyGKlbYPkwp82iw4CJlbquEmjKQoT',
            MAYHAN: '1ZJiBxodZDBWpgE7atndGZNlJ3jFggohI',
            KUTKUN: '1FFgu7QdDCQVJ71mw8TglaOOKHNn3oNg-',
            ATIKEOZCAN: '1Rg3y8PAgLVkU2_9iLLba-sEzqtHCi7ch',
        },
        mapApiKey: 'AIzaSyBbWNUj6lb2RpS4j5Dk45l_v_FOgLHfAF4',
    },
    jotform: {
        nakliye: 220662466006047,
        musteriZiyaret: 212662385555057,
        belgeOnayi: 212732206334042,
        musteriGeribildirim: 221096478573061,
    },
    todoist: {
        url: 'https://todoist.com',
        apiKey: 'ca4b8c58c465f1c46861ed8d53dd3113e5fdeb91',
        logo: 'https://w7.pngwing.com/pngs/957/657/png-transparent-todoist-task-management-computer-software-task-management-errands-angle-logo-microsoft-store.png',
    },
    algolia: {
        aaroCariAppID: 'KRI849R6E3',
        aaroProdAppID: 'CGDXS1T771',
        aaroCariSearchKey: 'c28f6eca4f54a777ebd8f34266f3f737',
        aaroProdSearchKey: '97e74852ec3ad2114008949befdf3028',
    },
};

const dev = {
    random: {
        ANONYMOUS_USER_IMAGE:
            'https://e7.pngegg.com/pngimages/347/642/png-clipart-facebook-social-media-councillor-okehampton-anonymous-silhouette-anonymous-thumbnail.png',
    },
    url: {
        API_URL: 'http://localhost:4000',
        AARO_BASE: 'https://erp2.aaro.com.tr',
        AARO_CARI_KALEM: 'https://erp2.aaro.com.tr/Cari/Kalem',
        ENV_URL: 'http://localhost:3000',
        DOMAIN: 'localhost',
    },
    images: {
        uKasa: 'https://www.kapim.com.tr/wp-content/uploads/2021/12/MDF-U-Kasa.png',
        kaplamaliKasa: 'https://www.kapim.com.tr/wp-content/uploads/2021/12/Alpi-Ceviz.png',
        uPervaz: 'https://market.kahramanlar.com.tr/wp-content/uploads/2022/01/pervaz-u.png',
        kaplamaliPervaz: 'https://www.kapim.com.tr/wp-content/uploads/2021/12/Alpi-Ceviz-6.png',
        kanat: 'https://www.kapim.com.tr/wp-content/uploads/2021/09/Yeni-Model-4-Beyaz.png',
        boya: 'https://pngimg.com/uploads/box/box_PNG92.png',
        offerBackground:
            'https://kahramanlar.s3.amazonaws.com/projects/L%C3%9CY%20ORM.%C3%9CRN.LTD.%20%C5%9ET%C4%B0./L%C3%BCy%20500%20Kap%C4%B1/ky8ylfb6-kahramanlar-offer-background.png',
    },
    fixedID: {
        PROJECT_STATUS_TAG_GROUP_ID: '613ba1fe84844e1d3eec1aa8',
        DEMAND_STATUS_TAG_GROUP_ID: '615c2d05fa1a97148016c05d',
        PARAMS_CATEGORY_GROUP_ID: '6229e05ce95b82177521c408',
        CUSTOMER_PROFILE_GROUP_ID: '6258d9d610c38e23b3e91b71',
        CUSTOMER_TYPE_GROUP_ID: '625d357d963b46364650e92e',
        CUSTOMER_INTERESTS_GROUP_ID: '625d391cfe79de37ce112e57',
        CUSTOMER_SOURCE_GROUP_ID: '625d3ae0fe79de37ce112e5d',
        PROJECT_LOSE_GROUP_ID: '6351125e0662ec6f70bbf2ab',
        PROJECT_WIN_GROUP_ID: '635119f8b7229172971dcaea',
        PROJECT_SOURCE_GROUP_ID: '6351215c431ee274881bc0c6',
        PROJECT_WINNER_GROUP_ID: '635128b7431ee274881bc162',
        PROJECT_TAGS_GROUP_ID: '6756d202bb098993f8f58403',
        LEAD_CATEGORY_GROUP_ID: '6373285e3e20ba24787b923d',
        DEMAND_TEMPLATE_CATEGORY_ID: '64d0958d7929a006fd7c396d',
        DEMAND_TEMPLATE_PRODUCT_ID: '64d097c27929a006fd7c3a4c',
    },
    customer: {
        potentialID: '625d3750963b46364650e92f',
    },
    statusID: {
        uretiliyor: '61d4997a9bbb4b070e2b27a3',
        uretildi: '61d4999c9bbb4b070e2b27a4',
        kaybedildi: '613bb01afe7ffb20b295ed0f',
        teklifGonderildi: '613ba22784844e1d3eec1aaa',
        kazanildi: '6152c875fdc886e98957f63f',
        girdiBekliyor: '62028e9092731329e64b1bfa',
    },
    demandStatusID: {
        fiyatVerildi: '615c2d37fa1a97148016c05e',
        duzeltilmeli: '615c93acb65d721c0a98dd86',
        revizeBekleniyor: '615c93c6b65d721c0a98dd87',
        fiyatBekleniyor: '615c93f1b65d721c0a98dd88',
    },
    pusher: {
        id: 'bff6464292c121f051a1',
    },
    google: {
        rootFolderID: '1QdwKGXLhfM92QkmT7wwFs2CflOAckPzX',
        priceListFolderName: '2024-08-11',
        googleSheetID: '1dtoLSlVujTAqqQAIs19DqwlmsHskXD0jSC0HMxPBPUA',
        sheetPageIDs: {
            kasa: 1972425608,
            kanat: 1777600013,
            pervaz: 1571720213,
            uKasa: 1180190356,
            uPervaz: 237928996,
            diger: 1285798873,
        },
        userOfferFolders: {
            SAMIL: '1Y8b1Xwp5z9aMFLtyLF5NVU_FdrBfKl_S',
            TOLGA: '1eqSzMaSDU9s_OahPD1NE-p-hicBQIABR',
            SGOLLU: '1b3oCWlwOvRGmXtO1NzEZCg66ofNREcdA',
            SERHATKAHRAMAN: '14bobwkXNm1DLutW6zqJsnAxrIi1ooh2J',
            SARIKDOGAN: '13uz3oOk1oeNkPz3LKRY9SmVazpks75AZ',
            BCINGI: '1U5p65b0Jl3mejSqh9nXpzj1R48MxwN0S',
            BILALK: '1PvUArS5FjsP_qfEjslxFCal6-JeYx2TG',
            ARIFBILICI: '1ReAywXANIEIO3raB-kcZ-PkjMhIaHpK9',
            ALIKEMALK: '1m7b2VVmrIqt0xx0LAubrL0JMcfFBPRlQ',
            AYILMAZ: '1IlJMyGKlbYPkwp82iw4CJlbquEmjKQoT',
            MAYHAN: '1ZJiBxodZDBWpgE7atndGZNlJ3jFggohI',
            KUTKUN: '1FFgu7QdDCQVJ71mw8TglaOOKHNn3oNg-',
            ATIKEOZCAN: '1Rg3y8PAgLVkU2_9iLLba-sEzqtHCi7ch',
        },
        // kahramanjjjj@gmail.com
        mapApiKey: 'AIzaSyBbWNUj6lb2RpS4j5Dk45l_v_FOgLHfAF4',
    },
    jotform: {
        nakliye: 220662466006047,
        musteriZiyaret: 212662385555057,
        belgeOnayi: 212732206334042,
        musteriGeribildirim: 221096478573061,
    },
    todoist: {
        url: 'https://todoist.com',
        apiKey: 'ca4b8c58c465f1c46861ed8d53dd3113e5fdeb91',
        logo: 'https://w7.pngwing.com/pngs/957/657/png-transparent-todoist-task-management-computer-software-task-management-errands-angle-logo-microsoft-store.png',
    },
    algolia: {
        aaroCariAppID: 'KRI849R6E3',
        aaroProdAppID: 'CGDXS1T771',
        aaroCariSearchKey: 'c28f6eca4f54a777ebd8f34266f3f737',
        aaroProdSearchKey: '97e74852ec3ad2114008949befdf3028',
    },
};
export const config = process.env.REACT_APP_NODE_ENV === 'development' ? dev : prod;

// Libraries

// Layout

// MD2 PRO

// @mui

// Project Pages And Components

// API

// Helpers
