import React from 'react';
import MDBox from 'components/MDBox';
import MDAvatar from 'components/MDAvatar';
import MDTypography from 'components/MDTypography';
import Tooltip from '@mui/material/Tooltip';
import team4 from 'assets/images/team-4.jpg';
import { formatDate } from 'helpers/dateHelpers';
const avatarStyles = {
    border: ({ borders: { borderWidth }, palette: { white } }) => `${borderWidth[2]} solid ${white.main}`,
    cursor: 'pointer',
    position: 'relative',
    ml: -1.5,
    '&:hover, &:focus': {
        zIndex: '10',
    },
};

export default function ListDetail({ listData, taskData }) {
    const { sharedWith, name, description, createdBy, createdAt, updatedAt } = listData;
    // ! TaskListID as a second parameter necessary because taskID can be null

    const completedTasksNumber = taskData.filter((el) => el.completed).length;
    const allTasksNumber = taskData.length;
    const ratio = allTasksNumber !== 0 ? (completedTasksNumber / allTasksNumber) * 100 : 0;

    const renderAvatars = (users) =>
        users.map((user) => (
            <Tooltip key={user.name} title={user.name} placement="top">
                <MDAvatar src={user?.profilePicture || team4} alt={user.name} size="xs" sx={avatarStyles} />
            </Tooltip>
        ));

    return (
        <MDBox color="white" variant="gradient" borderRadius="lg" shadow="lg" opacity={1} p={2}>
            <KeyValue label={'Liste Adı'} value={name} />
            <KeyValue label={'Açıklama'} value={description} />
            <KeyValue label={'Oluşturan'} value={createdBy?.name} />
            <KeyValue label={'Tamamlanma Oranı'} value={`${ratio.toFixed(0)}%`} />

            <KeyValue
                label={'Paylaşılanlar'}
                value={
                    <MDBox display="flex" flexDirection="row" justifyContent="space-evenly">
                        {renderAvatars(sharedWith)}
                    </MDBox>
                }
            />
            <KeyValue label={'Oluşturma Tarihi'} value={formatDate(createdAt)} />
            <KeyValue label={'Değiştirilme Tarihi'} value={formatDate(updatedAt)} />
            {/* <KeyValue
                label={'Oluşturan'}
                value={['info', 'warning', 'primary'].map((color, idx) => (
                    <MDBadge
                        key={color}
                        variant="gradient"
                        color={color}
                        badgeContent={`%${10 + idx * 5}\n)`}
                        container
                    />
                ))}
            /> */}

            {/* {ratio > 0 && (
                <MDBox display="flex" justifyContent="center" p={1}>
                    <CircularProgressWithLabel value={ratio} />
                </MDBox>
            )} */}
        </MDBox>
    );
}

const KeyValue = ({ label, value }) => (
    <MDBox key={label} display="flex" py={1} pr={2} justifyContent="space-between">
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {label}:{' '}
        </MDTypography>
        <MDTypography variant="button" fontWeight="regular" color="text" sx={{ flexShrink: 0 }}>
            {' '}
            {value}
        </MDTypography>
    </MDBox>
);
