import { useState, useEffect } from 'react';

// @mui material components
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Checkbox from '@mui/material/Checkbox';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';

// Material Dashboard 2 PRO React helper functions
import linearGradient from 'assets/theme/functions/linearGradient';
import { getTaskStyle } from 'helpers/taskHelpers';
import Tooltip from '@mui/material/Tooltip';
import team4 from 'assets/images/team-4.jpg';
import { notify } from 'helpers/notificationHelper';
import MDAvatar from 'components/MDAvatar';
// import { updateTask, completeTask } from 'services/api/general/task';
import { completeChildTask, completeParentTask } from 'services/api/general/task';
import { useQueryClient, useQuery } from 'react-query';
import { getUsers } from 'services/api/user';
import AaroLoadingScreen from 'components/Loading';
import Dialog from '@mui/material/Dialog';
import TaskModalView from 'components/General/Task/Views/Task/ModalView';
import ReactHowler from 'react-howler';
import clickSound from 'assets/sounds/clickSound.wav';

import ListItemSummary from './ListItemSummary';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddTodo from 'components/General/Task/utils/AddTodo';
import CircularProgress from '@mui/material/CircularProgress';
import TaskDetailLoadingGIF from 'components/Loading/TaskDetailLoadingGIF.js';

const { borderColor } = borders;
const { success } = colors;

const checkBoxStyle = {
    color: borderColor,

    '&.Mui-checked': {
        color: success.main,

        '& .MuiSvgIcon-root': {
            backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${linearGradient(
                success.main,
                success.main
            )}`,
            borderColor: success.main,
        },
    },
};

const fetchUsers = async (id) => {
    const response = await getUsers(id)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};

export default function SingleTask({ item, showResponsible = true, styleOverride }) {
    const {
        name = '', // String, required: true
        description = '', // String, required: false
        completed = false, // Boolean, default: false
        priority = -1, // Number, default: -1
        children = [], // Array of ObjectId, default: empty array
        taskList = null, // ObjectId, no default
        _id = null, // ObjectId, no default (this should ideally always be provided)
        parent = null, // ObjectId, default: null
        assignee = [], // Array of ObjectId, default: empty array
        createdBy = '',
    } = item;

    const queryClient = useQueryClient();
    const isParent = item.parent == null ? true : false;
    const [checked, setChecked] = useState(completed);
    const [openPageView, setOpenPageView] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [play, setPlay] = useState(false);
    const [showBadges, setShowBadges] = useState(false);
    const [completedRatio, setCompletedRatio] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const childrenNumber = children?.length;
        const completedChildrenNumber = children.filter((el) => el.completed);
        const ratio = childrenNumber > 0 && (completedChildrenNumber?.length / childrenNumber) * 100;
        setCompletedRatio(ratio);
    }, [children]);

    const allUsers = useQuery('fetchUsers', () => fetchUsers(), {
        staleTime: 600000,
    });

    const handleCheck = () => {
        setPlay(true);
        setTimeout(() => {
            setPlay(false);
        }, 1000);
    };

    const handleToggle = (e) => {
        e.preventDefault();

        const newCheckStatus = !checked;
        setChecked(newCheckStatus);

        if (newCheckStatus) handleCheck();

        if (newCheckStatus)
            completeParentTask(_id, newCheckStatus)
                .then((rp) => {
                    queryClient.invalidateQueries(['fetchSingleTask', _id]);
                    queryClient.invalidateQueries(['fetchTasks', taskList, parent]);
                    notify(rp.data.success, rp.data.message);
                })
                .catch((err) => notify(false, JSON.stringify(err)));
        else
            completeChildTask(_id, newCheckStatus).then((rp) => {
                queryClient.invalidateQueries(['fetchSingleTask', _id]);
                queryClient.invalidateQueries(['fetchTasks', taskList, parent]);

                notify(rp.data.success, rp.data.message);
            });
    };
    const wrapStyle = styleOverride?.wrapItems
        ? {}
        : {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
          };
    const taskStyle = getTaskStyle(priority, checked);

    if (allUsers.isLoading) return <AaroLoadingScreen />;
    if (allUsers.error) return 'Bir Hata Oluştu: ' + allUsers.error;

    const matchingResponsibles = allUsers.data.filter((el) => JSON.stringify(assignee).includes(el._id));

    return (
        <MDBox
            key={_id}
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderRadius="lg"
            py={1}
            pr={2}
            mb={1}
            onDoubleClick={() => {
                setOpenPageView(!openPageView);
            }}
            onMouseEnter={() => setShowBadges(true)}
        >
            <MDBox
                display="flex"
                alignItems="center"
                sx={{
                    flex: 1,
                    // minWidth: 0,
                    ...wrapStyle,
                }}
            >
                {isParent ? (
                    badgeIcon(children.length, taskStyle, loading, completed)
                ) : (
                    <MDBox display="flex" onDoubleClick={() => setOpenDialog(true)}>
                        {' '}
                        <IconButton aria-label="delete" onClick={() => setOpenDialog(true)}>
                            <EditIcon />
                        </IconButton>
                    </MDBox>
                )}
                <MDBox>
                    <MDBox
                        display="flex"
                        flexDirection="column"
                        // overflow="hidden auto"
                        sx={{
                            textDecorationLine: checked ? 'line-through' : 'none',
                        }}
                    >
                        <MDBox display="flex" alignItems="center">
                            <MDTypography variant="button" color="dark" fontWeight="medium" gutterBottom>
                                {name}
                            </MDTypography>
                            <MDBox pb={0.5} pl={0.5}>
                                <CircularProgress
                                    variant="determinate"
                                    value={Number(completedRatio)}
                                    size={17}
                                    color={
                                        'success'
                                        // completedRatio === 100 ? 'success' : completedRatio >= 50 ? 'warning' : 'error'
                                    }
                                    thickness={9}
                                />
                            </MDBox>
                        </MDBox>
                        <MDBox display="flex">
                            <MDTypography variant="caption" color="text">
                                {description} {` (${createdBy?.name})`}
                            </MDTypography>
                            {showResponsible && responsibles(matchingResponsibles)}
                        </MDBox>

                        {showBadges && <ListItemSummary taskID={_id} completed={checked} setLoading={setLoading} />}
                    </MDBox>
                </MDBox>
            </MDBox>

            <MDBox display="flex">
                <Checkbox
                    edge="end"
                    // defaultChecked={checked}
                    onChange={handleToggle}
                    checked={checked}
                    inputProps={{
                        'aria-labelledby': _id,
                    }}
                    sx={checkBoxStyle}
                />
                <ReactHowler src={clickSound} playing={play} />
            </MDBox>
            {/* Nested Todos Supported But Not Allowed */}
            <Dialog
                onClose={() => setOpenPageView(false)}
                open={isParent ? openPageView : false}
                fullWidth={true}
                maxWidth="xl"
            >
                <MDBox bgColor="light" variant="gradient">
                    <TaskModalView taskID={item._id} taskListID={item.taskList} />
                </MDBox>
            </Dialog>
            <Dialog onClose={() => setOpenDialog(false)} open={openDialog} fullWidth={true} maxWidth="sm">
                {/*Burada  */}
                <AddTodo initial={item} setOpenDialog={setOpenDialog} update={true} />
            </Dialog>
        </MDBox>
    );
}

const badgeIcon = (children, taskStyle, loading, completed) => {
    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: 13,
            top: 4,
            // border: `5px solid transparent`,
            padding: '0 4px',
            minWidth: '15px',
            minHeight: '15px',
            width: '15px',
            height: '15px',
            fontSize: '9px',
            lineHeight: '15px',
        },
    }));

    if (children > 0) {
        return (
            <StyledBadge color="error" badgeContent={children}>
                <MDBox
                    display="grid"
                    alignItems="center"
                    justifyContent="center"
                    bgColor={completed ? 'success' : 'secondary'}
                    borderRadius="lg"
                    shadow="md"
                    color="white"
                    width="2rem"
                    height="2rem"
                    mr={2}
                    variant="gradient"
                    fontSize="0.875rem"
                    sx={{
                        flex: '0 0 auto',
                    }}
                >
                    {loading ? <TaskDetailLoadingGIF /> : <Icon>{taskStyle.icon}</Icon>}
                </MDBox>
            </StyledBadge>
        );
    }
    return (
        <MDBox
            display="grid"
            alignItems="center"
            justifyContent="center"
            bgColor={completed ? 'success' : 'secondary'}
            borderRadius="lg"
            shadow="md"
            color="white"
            width="2rem"
            height="2rem"
            mr={2}
            variant="gradient"
            fontSize="0.875rem"
            sx={{
                flex: '0 0 auto',
            }}
        >
            {loading ? <TaskDetailLoadingGIF /> : <Icon>{taskStyle.icon}</Icon>}
        </MDBox>
    );
};

const responsibles = (responsibles) => {
    return (
        <MDBox display="flex" ml={1}>
            <MDBox
                // component="ul"
                display="flex"
                flexDirection="row"
                justifyContent="space-evenly"
            >
                {responsibles?.map((el) => (
                    <Tooltip title={el.name} placement="top" key={el?._id}>
                        <MDAvatar
                            src={el.profilePicture ? el.profilePicture : team4}
                            key={el?._id + 'mdAvatar'}
                            alt={el.name}
                            size="xs"
                            sx={({ borders: { borderWidth }, palette: { white } }) => ({
                                border: `${borderWidth[2]} solid ${white.main}`,
                                cursor: 'pointer',
                                position: 'relative',

                                '&:not(:first-of-type)': {
                                    ml: -1.25,
                                },

                                '&:hover, &:focus': {
                                    zIndex: '10',
                                },
                                minWidth: '19px',
                                minHeight: '19px',
                                width: '19px',
                                height: '19px',
                                fontSize: '9px',
                                lineHeight: '19px',
                            })}
                            variant="rounded"
                        />
                    </Tooltip>
                ))}
            </MDBox>
        </MDBox>
    );
};
