import React, { useState, useEffect } from 'react';
import { Token } from 'aaro';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { Grid, Card, CardHeader, CardContent, TextField, Button, Avatar, Divider } from '@mui/material';
import AbcIcon from '@mui/icons-material/Abc';
import GoogleIcon from '@mui/icons-material/Google';
import { updateUser, updateUserProfilePicture, initiateGoogleAuth } from 'services/api/user.js';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { config } from 'Constants';

import Header from 'layouts/accounts/components/Header/index.js';

export default function UserProfile() {
    const user = JSON.parse(localStorage.getItem('user'));
    const [email, setEmail] = useState(user.username + '@kahramanlar.com.tr');
    const [password, setPassword] = useState('');

    const [profilePicture, setProfilePicture] = useState(user?.profilePicture ? user?.profilePicture : '-1');

    const [disabled, setDisabled] = useState(false);

    const getGoogleConnectionStatus = () => {
        if (!user?.googleAuth?.accessToken || !user?.googleAuth?.expiryDate) {
            return { isConnected: false, remainingTime: null };
        }

        const expiryDate = new Date(user.googleAuth.expiryDate);
        const now = new Date();
        const isConnected = expiryDate > now;

        const remainingMs = expiryDate - now;
        const remainingHours = Math.max(0, Math.floor(remainingMs / (1000 * 60 * 60)));
        const remainingMinutes = Math.max(0, Math.floor((remainingMs % (1000 * 60 * 60)) / (1000 * 60)));

        return {
            isConnected,
            remainingTime: isConnected ? `${remainingHours} saat ${remainingMinutes} dakika` : null,
        };
    };

    const [googleStatus, setGoogleStatus] = useState(getGoogleConnectionStatus());

    useEffect(() => {
        const timer = setInterval(() => {
            setGoogleStatus(getGoogleConnectionStatus());
        }, 60000);

        return () => clearInterval(timer);
    }, []);

    async function handleSubmit(event) {
        event.preventDefault();
        const data = {
            password,
        };
        setDisabled(true);
        try {
            let isSuccess = false;

            const url = config.url.AARO_BASE;
            const aaroBearerToken = await Token(url, email, password)
                .then((rp) => (rp.data.access_token ? (isSuccess = true) : ''))
                .catch((err) => err);

            if (isSuccess) {
                const response = await updateUser(data);
                if (response.data.success) {
                    alert('Şifreniz başarıyla güncellendi, tekrar girişe yönlendiriliyorsunuz');

                    localStorage.removeItem('user');
                    window.location.reload();
                }
            } else alert('Yanlış şifre girişi yaptınız');
            setDisabled(false);
        } catch (err) {
            console.log(err);
        }
    }

    async function handleUserUpdate(event) {
        event.preventDefault();
        const data = {
            profilePicture,
        };
        setDisabled(true);
        try {
            const response = await updateUserProfilePicture(data);
            if (response.data.success) {
                alert('Bilgeleriniz güncellendi, tekrar girişe yönlendiriliyorsunuz');

                localStorage.removeItem('user');
                window.location.reload();
            }
            setDisabled(false);
        } catch (err) {
            console.log(err);
        }
    }

    const handleGoogleConnect = async () => {
        try {
            const response = await initiateGoogleAuth();

            if (response.data.success && response.data.result.authUrl) {
                window.location.href = response.data.result.authUrl;
            } else {
                throw new Error(response.data.message || 'Google auth URL bulunamadı');
            }
        } catch (error) {
            console.error('Google bağlantısı başlatılamadı:', error);
            alert(`Google bağlantısı başlatılamadı: ${error.message}`);
        }
    };

    return (
        <DashboardLayout>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={8}>
                    <Card>
                        <CardHeader title="Aaro Hesabınızı Bağlayın" avatar={<AbcIcon />} />
                        <CardContent>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    fullWidth
                                    label="Aaro Email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled
                                />
                                <TextField
                                    fullWidth
                                    label="Aaro Şifre"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <br />

                                <MDButton color="info" variant="contained" type="submit" disabled={disabled}>
                                    Güncelle
                                </MDButton>
                            </form>
                        </CardContent>
                    </Card>

                    <MDBox mt={3}>
                        <Card>
                            <CardHeader title="Google Hesabınızı Bağlayın" avatar={<GoogleIcon />} />
                            <CardContent>
                                <MDBox display="flex" flexDirection="column" gap={1} mb={2}>
                                    <MDTypography
                                        variant="body2"
                                        color={googleStatus.isConnected ? 'success' : 'error'}
                                    >
                                        {googleStatus.isConnected
                                            ? 'Google hesabınız bağlı'
                                            : 'Google hesabınız bağlı değil'}
                                    </MDTypography>

                                    {googleStatus.isConnected && googleStatus.remainingTime && (
                                        <MDTypography variant="caption" color="text">
                                            Bağlantı süresi: {googleStatus.remainingTime}
                                        </MDTypography>
                                    )}
                                </MDBox>

                                <MDButton
                                    variant="gradient"
                                    color={googleStatus.isConnected ? 'error' : 'info'}
                                    onClick={handleGoogleConnect}
                                    startIcon={<GoogleIcon />}
                                >
                                    {googleStatus.isConnected ? 'Bağlantıyı Kaldır' : 'Google ile Bağlan'}
                                </MDButton>
                            </CardContent>
                        </Card>
                    </MDBox>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Card>
                        <Header
                            avatar={user.profilePicture ? user.profilePicture : 'avatar'}
                            username={user?.username}
                            name={user?.name}
                        />
                        <CardContent>
                            <form onSubmit={handleUserUpdate}>
                                <TextField
                                    fullWidth
                                    label="Profil Resmi"
                                    type="string"
                                    value={profilePicture}
                                    onChange={(e) => setProfilePicture(e.target.value)}
                                />
                                <br />
                                <br />
                                <MDButton color="info" variant="contained" type="submit" disabled={disabled}>
                                    Güncelle
                                </MDButton>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}
