import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDBadgeDot from 'components/MDBadgeDot';
import PieChart from 'examples/Charts/PieChart';
import { useQueryClient } from 'react-query';
import useWindowDimensions from 'hooks/useWindowDimensions';

function ChannelsChart(props) {
    const { branchName, branchID, productID } = props;
    const queryClient = useQueryClient();
    const { height } = useWindowDimensions();

    const TipID = 10005; // Satış Faturası

    const data = queryClient.getQueryData([
        'fetchAllStockMovements',
        productID,
        branchID,
        TipID,
        '2023-01-01T00:00:00',
    ]);

    const [uniqueCustomerNumber, setUniqueCustomerNumber] = useState(0);
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: {
            label: 'Customers',
            backgroundColors: [],
            data: [],
        },
    });

    useEffect(() => {
        if (data) {
            const customerDistribution = data.reduce((acc, cur) => {
                const customerName = cur.CariAdi;
                acc[customerName] = (acc[customerName] || 0) + cur.Miktar;
                return acc;
            }, {});

            setUniqueCustomerNumber(Object.entries(customerDistribution).length);

            const sortedDistribution = Object.entries(customerDistribution)
                .sort((a, b) => b[1] - a[1]) // Sort by the quantity in descending order
                .slice(0, 5); // Take only the top 5

            const labels = [];
            const dataValues = [];
            const colors = ['info', 'primary', 'dark', 'secondary', 'success', 'error'];
            const backgroundColors = [];

            sortedDistribution.forEach(([name, value], index) => {
                labels.push(name.substring(0, 15));
                dataValues.push(value);
                backgroundColors.push(colors[index % colors.length]);
            });

            setChartData({
                labels,
                datasets: {
                    label: 'Customers',
                    backgroundColors,
                    data: dataValues,
                },
            });
        }
    }, [data]);

    if (!data) return null;

    return (
        <Card sx={{ height: height / 2.25 }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <MDTypography variant="h6">1 Ocak 2023 itibariyle bu ürünü alanlar ({branchName})</MDTypography>
            </MDBox>
            <MDBox mt={3}>
                <Grid container alignItems="center">
                    <Grid item xs={7}>
                        <PieChart chart={chartData} height="12.5rem" />
                    </Grid>
                    <Grid item xs={5}>
                        <MDBox pr={1}>
                            {chartData.labels.map((label, index) => (
                                <MDBox key={index} mb={1}>
                                    <MDBadgeDot
                                        color={chartData.datasets.backgroundColors[index]}
                                        size="sm"
                                        badgeContent={label}
                                    />
                                </MDBox>
                            ))}
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox pt={4} pb={2} px={2} display="flex" flexDirection={{ xs: 'column', sm: 'row' }} mt="auto">
                <MDTypography variant="caption">
                    Aktif ve pasif bütün projeler üzerinden hesaplanmaktadır. Toplam teklif verdiğiniz müşteri sayısı:{' '}
                    {uniqueCustomerNumber}
                </MDTypography>
            </MDBox>
        </Card>
    );
}

export default ChannelsChart;
