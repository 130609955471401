// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

function OrderSummary({
    discountTotal,
    discountTax,
    shipping,
    shippingTax,
    cartTax,
    total,
    totalTax,
    pricesIncludeTax,
    payment_method,
    payment_method_title,
    shipping_lines,
}) {
    return (
        <>
            <MDBox mb={2}>
                <MDTypography variant="h6" fontWeight="medium">
                    Sipariş Özeti
                </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                    Ara Toplam:
                </MDTypography>
                <MDBox ml={1}>
                    <MDTypography variant="body2" fontWeight="medium">
                        ₺{(total - totalTax - shipping - shippingTax + Number(discountTotal)).toFixed(2)}
                    </MDTypography>
                </MDBox>
            </MDBox>
            {Number(discountTotal) > 0 && (
                <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                        İndirim:
                    </MDTypography>
                    <MDBox ml={1}>
                        <MDTypography variant="body2" fontWeight="medium" color="error">
                            -₺{Number(discountTotal).toFixed(2)}
                        </MDTypography>
                    </MDBox>
                </MDBox>
            )}
            <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                    Kargo: {shipping_lines ? shipping_lines[0]?.method_title : ''}
                </MDTypography>
                <MDBox ml={1}>
                    <MDTypography variant="body2" fontWeight="medium">
                        ₺{Number(shipping).toFixed(2)}
                    </MDTypography>
                </MDBox>
            </MDBox>

            <MDBox display="flex" justifyContent="space-between" mt={3}>
                <MDTypography variant="body1" fontWeight="light" color="text">
                    Toplam:
                </MDTypography>
                <MDBox ml={1}>
                    <MDTypography variant="body1" fontWeight="medium">
                        ₺{Number(total).toFixed(2)}
                    </MDTypography>
                </MDBox>
            </MDBox>

            <MDBox mt={2}>
                <MDTypography variant="caption" fontWeight="regular" color="text">
                    Ödeme Yöntemi: {payment_method_title} | {payment_method}
                </MDTypography>
                {payment_method !== 'iyzico' && (
                    <MDTypography variant="caption" fontWeight="regular" color="primary" display="block">
                        Ödeme yöntemine dikkat edin!
                    </MDTypography>
                )}
            </MDBox>
        </>
    );
}

export default OrderSummary;
