/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';

import SingleAttachmentView from './FileView.js';
import { getFiles } from 'services/api/general/file';
import MDBox from 'components/MDBox';
export default function AttachmentCards(props) {
    const { relatedID, updateFiles, setUpdateFiles, source } = props;

    const [files, setFiles] = useState([]);
    useEffect(() => {
        const fetchFiles = async () => {
            getFiles(relatedID)
                .then((response) => {
                    const filteredFiles = response.data.result.filter(
                        (file) => file?.attachment?.uploadTarget !== 'drive'
                    );
                    console.log(filteredFiles);
                    setFiles(filteredFiles);
                })
                .catch((err) => {
                    console.error(err);
                });
        };
        fetchFiles();
    }, [updateFiles]);

    return (
        <MDBox py={files?.length > 0 ? 3 : 0}>
            <Grid container justify="center" spacing={3}>
                {files?.map((attachment) => (
                    <Grid item key={attachment._id} xs={12} sm={12} md={6}>
                        <MDBox mb={2}>
                            <SingleAttachmentView
                                attachment={attachment}
                                setUpdateFiles={setUpdateFiles}
                                source={source}
                            />
                        </MDBox>
                    </Grid>
                ))}
            </Grid>
        </MDBox>
    );
}
