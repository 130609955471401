import React, { useState, useEffect } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useJsApiLoader } from '@react-google-maps/api';
import { config } from 'Constants';
import { GOOGLE_MAPS_CONFIG } from './googleMapsConfig';

const AutoCompleteComponent = (props) => {
    const { changeLatLng } = props;
    const { mapApiKey } = config.google;

    const { isLoaded } = useJsApiLoader(GOOGLE_MAPS_CONFIG);

    const [value, setValue] = useState(null);

    useEffect(() => {
        if (value) {
            geocodeByAddress(value.label)
                .then((results) => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                    changeLatLng({ lat, lng, value });
                });
        }
    }, [value, changeLatLng]);

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <div>
            <GooglePlacesAutocomplete
                apiKey={mapApiKey}
                apiOptions={{ language: 'tr', region: 'tr' }}
                selectProps={{
                    value,
                    onChange: setValue,
                }}
            />
        </div>
    );
};

export default AutoCompleteComponent;
