import { useEffect, useRef, useState } from 'react';
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';
import MDBox from 'components/MDBox';
import MDDropzoneRoot from 'components/General/File/DropZoneRootStyled.js';
import { useMaterialUIController } from 'context';
import { postFileGoogleDrive } from 'services/api/general/file';

Dropzone.autoDiscover = false; // Move it outside of the component

// Function to normalize filename
function normalizeFilename(filename) {
    // replace non-alphanumeric, non-dot, and non-slash characters with underscore
    return filename.replace(/[^a-zA-Z0-9/]/g, '');
}
function MDDropzone({ options, relatedID, filePath, additionalName, source, setUpdateFiles }) {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dropzoneRef = useRef();
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user')); // Move it here

        let dz = new Dropzone(dropzoneRef.current, {
            ...options,
            addRemoveLinks: true,
            url: '#',
            dictDefaultMessage: 'Dosyalarınızı buraya bırakın veya tıklayın',
            maxFilesize: 75, // Maximum filesize in MB
            dictRemoveFile: 'Dosyayı Kaldır',
        });

        dz.on('sending', function (file, xhr, formData) {
            xhr.abort();

            let data = new FormData();

            data.append('relatedID', relatedID);
            data.append('name', file.name);
            data.append('filePath', filePath);
            data.append('user', user.userID);
            data.append('alt', '');
            data.append('attachment', file);
            data.append('uploadTarget', 'drive');
            file.previewElement.classList.add('dz-uploading');

            postFileGoogleDrive(data, normalizeFilename(additionalName.trim()), source)
                .then(() => {
                    setUpdateFiles(Math.random());
                    file.previewElement.classList.add('dz-success');
                    setErrorMessage('');
                    setTimeout(() => this.removeFile(file), 3000);
                })
                .catch((err) => {
                    console.log(err);
                    file.previewElement.classList.add('dz-error-custom');
                    setErrorMessage('Dosya yüklenirken bir hata oluştu');
                });
        });

        dz.on('error', function (file, message) {
            if (message.includes('File is too big')) {
                file.previewElement.classList.add('dz-error-custom');
                setErrorMessage("Dosya boyutu 75MB'dan büyük olamaz");
            }
        });

        return () => {
            dz.destroy(); // Remove the check
        };
    }, [options, relatedID, filePath, additionalName, source, setUpdateFiles]);

    return (
        <>
            <MDDropzoneRoot
                component="form"
                action="#"
                ref={dropzoneRef}
                className="form-control dropzone"
                ownerState={{ darkMode }}
            >
                <MDBox className="fallback" bgColor="transparent">
                    <MDBox component="input" name="file" type="file" multiple />
                </MDBox>
            </MDDropzoneRoot>
            {errorMessage && (
                <MDBox mt={1} color="error">
                    {errorMessage}
                </MDBox>
            )}
        </>
    );
}

export default MDDropzone;
