import { useState, createContext, useEffect } from 'react';
import Layout from 'layouts/purchases/pages/allLeads/layout/index.js';
import { useQuery } from 'react-query';
import AaroLoadingScreen from 'components/Loading';
import MDBox from 'components/MDBox';
import DraggableList from 'layouts/purchases/pages/allLeads/DraggableList';
import { config } from 'Constants';
import { getWooOrders } from 'services/outside/woo/orders.js';
import { getParameters } from 'services/api/general/parameter';
import { getPurchases } from 'services/api/purchases';

import { subDays } from 'date-fns';
import Paper from '@mui/material/Paper';
import LeadsMap from 'layouts/lead/pages/allLeads/LeadsMap';
import { isMobile } from 'helpers/smallCodes';
import ArasShipments from 'components/General/Shipments/Aras';

export const AllPurchasesContext = createContext();

const fetchParameters = async (keys) => {
    const queryString = keys
        .split(',')
        .map((key) => `key=${key}`)
        .join('&');
    const response = await getParameters(queryString).then((rp) => (rp.data?.result ? rp.data?.result : []));
    return response;
};

const fetchWooOrders = async (consumerKey, consumerSecret, baseUrl, days, searchTerm = '') => {
    try {
        const [allOrders, purchases] = await Promise.all([
            (async () => {
                const thirtyDaysAgo = new Date(subDays(new Date(), days)).toISOString();
                const today = new Date().toISOString();
                let page = 1;
                let allOrders = [];
                let hasMore = true;

                while (hasMore) {
                    const params = {
                        after: thirtyDaysAgo,
                        before: today,
                        per_page: 100,
                        page: page,
                        status: ['processing', 'completed'],
                        search: searchTerm,
                    };

                    const response = await getWooOrders(consumerKey, consumerSecret, baseUrl, params);

                    if (response.data && response.data.length > 0) {
                        allOrders = [...allOrders, ...response.data];
                        page++;
                    } else {
                        hasMore = false;
                    }
                }
                return allOrders;
            })(),
            getPurchases()
                .then((response) => response.data?.result || [])
                .catch((error) => {
                    console.error('Purchases fetch error:', error);
                    return []; // Return empty array if purchases fetch fails
                }),
        ]);

        const purchaseMap = purchases.reduce((acc, purchase) => {
            if (purchase.wooCommerceID) {
                acc[purchase.wooCommerceID] = purchase;
            }
            return acc;
        }, {});

        const ordersWithPurchases = allOrders.map((order) => ({
            ...order,
            purchase: purchaseMap[order.id] || null,
        }));

        return ordersWithPurchases;
    } catch (error) {
        console.error('Error in fetchWooOrders:', error);
        throw error; // Re-throw the error for the query to handle
    }
};

// Woo siparişlerini kanban veri yapısına dönüştürür
function convertWooOrdersToKanbanData(orders) {
    const result = {
        processing: {
            name: 'Hazırlanıyor',
            items: [],
        },

        completed: {
            name: 'Tamamlandı',
            items: [],
        },
    };

    orders?.forEach((order) => {
        result[order.status].items.push({
            id: order.id,
            name: `#${order.number} - ${order.billing?.first_name} ${order.billing?.last_name}`,
            content: order,
        });
    });

    return result;
}

const plateCodeToCoordinates = {
    '01': { lat: 37.0, lng: 35.3213 }, // Adana
    '02': { lat: 37.7648, lng: 38.2786 }, // Adıyaman
    '03': { lat: 38.6191, lng: 30.7169 }, // Afyonkarahisar
    '04': { lat: 39.7191, lng: 41.2867 }, // Ağrı
    '05': { lat: 40.65, lng: 35.8333 }, // Amasya
    '06': { lat: 39.9334, lng: 32.8597 }, // Ankara
    '07': { lat: 36.8969, lng: 30.7133 }, // Antalya
    '08': { lat: 41.1828, lng: 41.8183 }, // Artvin
    '09': { lat: 37.8493, lng: 27.8456 }, // Aydın
    10: { lat: 39.6484, lng: 27.8826 }, // Balıkesir
    11: { lat: 40.1579, lng: 30.0665 }, // Bilecik
    12: { lat: 38.3949, lng: 40.1847 }, // Bingöl
    13: { lat: 37.5744, lng: 42.7599 }, // Bitlis
    14: { lat: 40.735, lng: 31.6089 }, // Bolu
    15: { lat: 37.7611, lng: 30.5569 }, // Burdur
    16: { lat: 40.1828, lng: 29.0665 }, // Bursa
    17: { lat: 40.1456, lng: 26.4064 }, // Çanakkale
    18: { lat: 40.6013, lng: 33.6134 }, // Çankırı
    19: { lat: 40.5506, lng: 34.9556 }, // Çorum
    20: { lat: 37.7833, lng: 29.0907 }, // Denizli
    21: { lat: 37.9144, lng: 40.2306 }, // Diyarbakır
    22: { lat: 41.6704, lng: 26.5649 }, // Edirne
    23: { lat: 38.6743, lng: 39.2232 }, // Elazığ
    24: { lat: 39.9208, lng: 41.2756 }, // Erzincan
    25: { lat: 39.9043, lng: 41.2658 }, // Erzurum
    26: { lat: 39.7667, lng: 30.5256 }, // Eskişehir
    27: { lat: 37.0662, lng: 37.3833 }, // Gaziantep
    28: { lat: 40.9176, lng: 38.3874 }, // Giresun
    29: { lat: 40.46, lng: 39.4817 }, // Gümüşhane
    30: { lat: 37.5847, lng: 43.7336 }, // Hakkari
    31: { lat: 36.5984, lng: 36.1989 }, // Hatay
    32: { lat: 37.7635, lng: 30.5537 }, // Isparta
    33: { lat: 36.8121, lng: 34.6415 }, // Mersin
    34: { lat: 41.0082, lng: 28.9784 }, // İstanbul
    35: { lat: 38.4237, lng: 27.1428 }, // İzmir
    36: { lat: 40.6015, lng: 43.0978 }, // Kars
    37: { lat: 41.21, lng: 32.6544 }, // Kastamonu
    38: { lat: 38.7322, lng: 35.4853 }, // Kayseri
    39: { lat: 41.7333, lng: 27.2167 }, // Kırklareli
    40: { lat: 39.1425, lng: 35.3213 }, // Kırşehir
    41: { lat: 40.7669, lng: 30.3947 }, // Kocaeli
    42: { lat: 37.8667, lng: 32.4833 }, // Konya
    43: { lat: 39.4192, lng: 29.9853 }, // Kütahya
    44: { lat: 38.3735, lng: 38.319 }, // Malatya
    45: { lat: 38.6191, lng: 27.4289 }, // Manisa
    46: { lat: 37.5833, lng: 36.9371 }, // Kahramanmaraş
    47: { lat: 37.3212, lng: 40.7245 }, // Mardin
    48: { lat: 36.8667, lng: 28.2667 }, // Muğla
    49: { lat: 37.9293, lng: 41.9401 }, // Muş
    50: { lat: 38.6244, lng: 34.7239 }, // Nevşehir
    51: { lat: 37.9783, lng: 34.6769 }, // Niğde
    52: { lat: 40.9833, lng: 37.8833 }, // Ordu
    53: { lat: 41.0201, lng: 40.5234 }, // Rize
    54: { lat: 40.734, lng: 30.3344 }, // Sakarya
    55: { lat: 41.2867, lng: 36.33 }, // Samsun
    56: { lat: 37.9274, lng: 41.9444 }, // Siirt
    57: { lat: 42.0264, lng: 35.1551 }, // Sinop
    58: { lat: 39.7477, lng: 37.0179 }, // Sivas
    59: { lat: 41.2, lng: 27.3333 }, // Tekirdağ
    60: { lat: 40.35, lng: 36.55 }, // Tokat
    61: { lat: 41.0015, lng: 39.7178 }, // Trabzon
    62: { lat: 40.2645, lng: 40.2286 }, // Tunceli
    63: { lat: 37.1671, lng: 38.7955 }, // Şanlıurfa
    64: { lat: 38.6806, lng: 29.4082 }, // Uşak
    65: { lat: 38.4942, lng: 43.3832 }, // Van
    66: { lat: 39.7276, lng: 34.768 }, // Yozgat
    67: { lat: 41.4564, lng: 31.7987 }, // Zonguldak
    68: { lat: 38.373, lng: 34.0268 }, // Aksaray
    69: { lat: 40.2552, lng: 40.2249 }, // Bayburt
    70: { lat: 37.1809, lng: 33.215 }, // Karaman
    71: { lat: 39.8468, lng: 33.5153 }, // Kırıkkale
    72: { lat: 37.9215, lng: 41.9459 }, // Batman
    73: { lat: 37.4187, lng: 42.4918 }, // Şırnak
    74: { lat: 41.1411, lng: 32.7124 }, // Bartın
    75: { lat: 41.1192, lng: 42.7033 }, // Ardahan
    76: { lat: 39.888, lng: 44.0048 }, // Iğdır
    77: { lat: 40.66, lng: 29.23 }, // Yalova
    78: { lat: 41.198, lng: 32.628 }, // Karabük
    79: { lat: 36.7164, lng: 37.1151 }, // Kilis
    80: { lat: 37.0742, lng: 36.2467 }, // Osmaniye
    81: { lat: 40.8438, lng: 31.1565 }, // Düzce
};

// Siparişleri koordinat eklenmiş olarak döndür
function addCoordinatesToOrders(orders) {
    return orders.map((order) => {
        const plateCode = order.shipping?.state.substring(2);
        const coordinates = plateCodeToCoordinates[plateCode];
        return {
            ...order,
            location: coordinates || null,
        };
    });
}

// Tüm lead'leri gösteren ana bileşen
export default function WooOrders() {
    // Aktif lead'leri gösterip göstermeme durumunu kontrol eden state
    const [showActiveLeads, setShowActiveLeads] = useState(true);
    // Arama metnini tutan state
    const [search, setSearch] = useState('');
    const [days, setDays] = useState(15);
    const [showShipments, setShowShipments] = useState(false);

    const paramQuery = 'api_woo_url,api_woo_cs,api_woo_ck';
    const {
        data: parametersData,
        isLoading: parametersIsLoading,
        error: parametersError,
    } = useQuery(['fetchParameters', paramQuery], () => fetchParameters(paramQuery), { staleTime: 600000 });

    // Parse parameters into separate variables
    const wooConfig =
        parametersData?.reduce((acc, param) => {
            acc[param.key] = param.value;
            return acc;
        }, {}) || {};

    const { api_woo_url, api_woo_cs, api_woo_ck } = wooConfig;

    // WooCommerce siparişlerini çeken query
    const [kanbanData, setKanbanData] = useState(null);

    const {
        data: wooOrders,
        isLoading: wooOrdersLoading,
        error: wooOrdersError,
        refetch,
    } = useQuery(
        ['wooOrders', api_woo_url, days, search.trim()],
        async () => {
            if (!api_woo_url || !api_woo_cs || !api_woo_ck) {
                throw new Error('WooCommerce yapılandırma bilgileri eksik');
            }

            const baseUrl = api_woo_url.replace(/\/?$/, '/');
            return fetchWooOrders(api_woo_ck, api_woo_cs, baseUrl, days, search.trim());
        },
        {
            enabled: !!(api_woo_url && api_woo_cs && api_woo_ck),
            staleTime: 300000,
            retry: false,
            // Her sorgu sonucunda kanbanData'yı güncelle
            onSuccess: (data) => {
                const newKanbanData = convertWooOrdersToKanbanData(data);
                setKanbanData(newKanbanData);
            },
        }
    );

    // Modify kanban data for mobile view
    const displayKanbanData = isMobile()
        ? {
              processing: kanbanData?.processing || { name: 'Hazırlanıyor', items: [] },
          }
        : kanbanData;

    // Yükleme ve hata kontrolleri
    if (parametersIsLoading || wooOrdersLoading) {
        return <AaroLoadingScreen />;
    }

    if (parametersError || wooOrdersError) {
        return (
            <div style={{ padding: '20px', color: 'red' }}>
                <h3>Bağlantı Hatası</h3>
                <p>{parametersError?.message || wooOrdersError?.message}</p>
            </div>
        );
    }

    // Siparişlere koordinatları ekle
    const ordersWithCoordinates = addCoordinatesToOrders(wooOrders);
    // Bileşeni render et
    return (
        <AllPurchasesContext.Provider
            value={{
                setShowActiveLeads,
                showActiveLeads,
                search,
                setSearch,
                days,
                setDays,
                refetch,
                showShipments,
                setShowShipments,
            }}
        >
            <Layout>
                <MDBox opacity={1} px={2}>
                    {!isMobile() && showShipments && (
                        <MDBox mb={10}>
                            <ArasShipments />
                        </MDBox>
                    )}
                    <MDBox
                        sx={{
                            '& .react-kanban-board': {
                                display: 'flex',
                                flexDirection: {
                                    xs: 'column',
                                    md: 'row',
                                },
                                gap: 2,
                                '& > div': {
                                    width: {
                                        xs: '100%',
                                        md: 'auto',
                                    },
                                    marginBottom: {
                                        xs: 2,
                                        md: 0,
                                    },
                                },
                            },
                        }}
                    >
                        <DraggableList
                            key={search}
                            data={displayKanbanData}
                            wooConfig={{
                                consumerKey: api_woo_ck,
                                consumerSecret: api_woo_cs,
                                websiteUrl: api_woo_url,
                            }}
                        />
                    </MDBox>
                </MDBox>
                {!isMobile() && (
                    <Paper elevation={3} sx={{ height: 450, overflow: 'hidden' }}>
                        <LeadsMap data={ordersWithCoordinates.filter((order) => order.location) || []} />
                    </Paper>
                )}
            </Layout>
        </AllPurchasesContext.Provider>
    );
}
