import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import CircularProgress from '@mui/material/CircularProgress';
import { handleGoogleCallback } from 'services/api/user';

function GoogleCallback() {
    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState(null);

    useEffect(() => {
        const processCallback = async () => {
            const searchParams = new URLSearchParams(location.search);
            const code = searchParams.get('code');
            console.log('Auth Code:', code);

            if (!code) {
                setError('Authorization code not found');
                return;
            }

            try {
                const response = await handleGoogleCallback(code);
                console.log('Backend Response:', response);
                console.log('Response Data:', response.data);
                console.log('Result:', response.data?.result);

                if (response.data.success) {
                    const user = JSON.parse(localStorage.getItem('user'));
                    console.log('Current User:', user);

                    const updatedUser = {
                        ...user,
                        googleAuth: {
                            accessToken: response.data.result?.accessToken,
                            refreshToken: response.data.result?.refreshToken,
                            expiryDate: response.data.result?.expiryDate,
                        },
                    };
                    console.log('Updated User:', updatedUser);

                    localStorage.setItem('user', JSON.stringify(updatedUser));

                    navigate('/account/settings', {
                        state: { message: 'Google hesabınız başarıyla bağlandı!' },
                    });
                } else {
                    setError(response.data.message || 'Google bağlantısı başarısız oldu');
                }
            } catch (err) {
                console.error('Full Error:', err);
                console.error('Error Response:', err.response);
                const errorMessage = err.response?.data?.message || err.message;
                setError('Bir hata oluştu: ' + errorMessage);
            }
        };

        processCallback();
    }, [location, navigate]);

    return (
        <DashboardLayout>
            <MDBox display="flex" justifyContent="center" alignItems="center" minHeight="60vh" flexDirection="column">
                {error ? (
                    <MDTypography variant="h4" color="error" textAlign="center">
                        {error}
                    </MDTypography>
                ) : (
                    <>
                        <CircularProgress size={40} />
                        <MDTypography variant="h4" mt={2}>
                            Google hesabınız bağlanıyor...
                        </MDTypography>
                    </>
                )}
            </MDBox>
        </DashboardLayout>
    );
}

export default GoogleCallback;
