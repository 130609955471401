// Libraries

// Layout

// MD2 PRO
import MDBox from 'components/MDBox';
// @mui
import Grid from '@mui/material/Grid';

// API

import SingleProduct from 'layouts/orders/pages/SingleOrder/MainPage/components/SingleProduct.js';
// Helpers

function MainPage({ products, document }) {
    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {/* {JSON.stringify(products)} */}

                    <MDBox display="flex" flexDirection="column">
                        {products?.map((product) => (
                            <MDBox mb={3}>
                                <SingleProduct key={product?.StokID} product={product} />
                            </MDBox>
                        ))}
                    </MDBox>

                    {/* <DataTable
						table={dataTableData}
						entriesPerPage={false}
						showTotalEntries={false}
						isSorted={false}
						noEndBorder
					/> */}
                </Grid>
            </Grid>
        </MDBox>
    );
}

/*
	<Grid item xs={12} lg={4}>
						<MDBox mb={3}>
							<CustomerDetails
								customerID={customerID}
								icon="person"
								color="dark"
							/>
							</MDBox>

							<MDBox mb={3}>
								<AaroCustomerSales customerID={customerID} />
							</MDBox>
						</Grid>

*/

export default MainPage;
