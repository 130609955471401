import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import Card from '@mui/material/Card';
import { GOOGLE_MAPS_CONFIG } from './googleMapsConfig';

const MapComponent = ({ enableCard, position, height, margin, zoom, latLng }) => {
    const [selectedPlace, setSelectedPlace] = React.useState(null);
    const [activeMarker, setActiveMarker] = React.useState(null);

    const { isLoaded } = useJsApiLoader(GOOGLE_MAPS_CONFIG);

    const containerStyle = {
        position: position || 'relative',
        height: height || '40vh',
        margin: margin || '20px',
    };

    const onMarkerClick = (marker) => {
        setSelectedPlace({ name: 'Kahramanlar Orman Ürünleri' });
        setActiveMarker(marker);
    };

    const onClose = () => {
        setSelectedPlace(null);
        setActiveMarker(null);
    };

    const renderMap = () => (
        <GoogleMap mapContainerStyle={containerStyle} center={latLng} zoom={zoom || 10}>
            <Marker position={latLng} onClick={(marker) => onMarkerClick(marker)} />
            {activeMarker && selectedPlace && (
                <InfoWindow position={latLng} onCloseClick={onClose}>
                    <div>
                        <h4>{selectedPlace.name}</h4>
                    </div>
                </InfoWindow>
            )}
        </GoogleMap>
    );

    if (!isLoaded) return <div>Loading...</div>;

    return enableCard ? <Card>{renderMap()}</Card> : renderMap();
};

MapComponent.propTypes = {
    enableCard: PropTypes.bool,
    position: PropTypes.string,
    height: PropTypes.string,
    margin: PropTypes.string,
    zoom: PropTypes.number,
    latLng: PropTypes.object.isRequired,
};

export default MapComponent;
