import { useLocation } from 'react-router';
import { Navigate, Outlet } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const ProtectedRoutes = () => {
	const location = useLocation();
	const user = JSON.parse(localStorage.getItem('user'));

	let validation = false;
	// getStock({ StokID: 9516 })
	// 	.then((rp) => {
	// 		if (!rp.data.Sonuc) ReLogin();
	// 	})
	// 	.catch((err) =>
	// 		console.log('Protected route aaro token control error - ' + err)
	// 	);

	if (user !== null && user !== undefined) {
		const bearerToken = user.token;
		const jwtToken = jwt_decode(bearerToken?.replace('Bearer', ''));
		const currentDate = new Date().getTime() / 1000;
		if (currentDate < jwtToken.exp) validation = true;
	}

	return validation ? (
		<Outlet />
	) : (
		<Navigate
			to="/login"
			replace
			state={{ from: location }}
		/>
	);
};




export default ProtectedRoutes;
