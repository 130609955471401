import axios from 'axios';
import { config } from 'Constants';

/**
 * @desc  it retrieves all the customers
 * @return promise - axios returns a promise
 */
export const getUsersByRole = (role) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/users/all/${role}`;
    return axios.get(url, { headers });
};

/**
 * @desc  it retrieves all the customers
 * @return promise - axios returns a promise
 */
export const getUsers = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/users/all`;
    return axios.get(url, { headers });
};

/**
 * @desc  it updates the user
 * @return promise - axios returns a promise
 */
export const updateUser = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/users/password`;
    return axios.put(url, data, { headers });
};

/**
 * @desc  it updates the user
 * @return promise - axios returns a promise
 */
export const updateUserProfilePicture = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/users/aaro`;
    return axios.put(url, data, { headers });
};

/**
 * @desc
 * @return promise - axios returns a promise
 */
export const login = (data) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    const url = `${config.url.API_URL}/api/users/login-official`;
    return axios.post(url, data, { headers });
};

/**
 * @desc  Initiates Google OAuth process
 * @return promise - axios returns a promise
 */
export const initiateGoogleAuth = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/users/auth/google/init`;
    return axios.get(url, { headers });
};

/**
 * @desc  Handles Google OAuth callback
 * @return promise - axios returns a promise
 */
export const handleGoogleCallback = (code) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        Authorization: user.token,
    };
    const url = `${config.url.API_URL}/api/users/auth/google/callback`;
    return axios.get(`${url}?code=${code}`, { headers });
};

/**
 * @desc  Refreshes Google token if expired
 * @return promise
 */
export const refreshGoogleToken = async (user) => {
    if (!user.googleAuth?.refreshToken) {
        console.log('No refresh token found');
        return null;
    }

    const expiryDate = new Date(user.googleAuth.expiryDate);
    const now = new Date();

    console.log('Token Status:', {
        expiryDate,
        now,
        isExpired: now >= expiryDate,
        refreshToken: user.googleAuth.refreshToken,
    });

    if (now >= expiryDate) {
        try {
            console.log('Attempting to refresh token with:', {
                url: `${config.url.API_URL}/api/users/auth/google/refresh`,
                token: user.token,
            });

            const response = await axios.post(
                `${config.url.API_URL}/api/users/auth/google/refresh`,
                { refreshToken: user.googleAuth.refreshToken }, // refresh token'ı body'de gönderelim
                {
                    headers: {
                        Authorization: user.token,
                    },
                }
            );

            console.log('Refresh response:', response.data);

            if (response.data.success) {
                const updatedUser = {
                    ...user,
                    googleAuth: response.data.result,
                };
                localStorage.setItem('user', JSON.stringify(updatedUser));
                return updatedUser;
            }
        } catch (err) {
            console.error('Google token refresh failed:', {
                error: err,
                response: err.response?.data,
                status: err.response?.status,
            });
            return null;
        }
    }

    return user;
};
