// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// OrderDetails page components
import Header from 'layouts/purchases/pages/singleLead/Sections/Main/order-details/components/Header';
import OrderInfo from 'layouts/purchases/pages/singleLead/Sections/Main/order-details/components/OrderInfo';
import BillingInformation from 'layouts/purchases/pages/singleLead/Sections/Main/order-details/components/BillingInformation';
import ShippingInformation from 'layouts/purchases/pages/singleLead/Sections/Main/order-details/components/ShippingInformation';
import OrderSummary from 'layouts/purchases/pages/singleLead/Sections/Main/order-details/components/OrderSummary';

function OrderDetails({ wooCommerceOrderDetails }) {
    return (
        <MDBox>
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} lg={12}>
                    <Card>
                        <MDBox pt={2} px={2}>
                            <Header
                                orderNumber={wooCommerceOrderDetails.number}
                                orderDate={wooCommerceOrderDetails.date_created}
                                customerName={`${wooCommerceOrderDetails.billing.first_name} ${wooCommerceOrderDetails.billing.last_name}`}
                            />
                        </MDBox>
                        <Divider />
                        <MDBox pt={1} pb={3} px={2}>
                            <MDBox mb={3}>
                                <OrderInfo line_items={wooCommerceOrderDetails.line_items} />
                            </MDBox>
                            <Divider />
                            <MDBox mt={3}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <MDBox mt={3}>
                                            <BillingInformation billing={wooCommerceOrderDetails.billing} />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <MDBox mt={3}>
                                            <ShippingInformation shipping={wooCommerceOrderDetails.shipping} />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} lg={4} sx={{ ml: 'auto' }}>
                                        <OrderSummary
                                            discountTotal={wooCommerceOrderDetails.discount_total}
                                            discountTax={wooCommerceOrderDetails.discount_tax}
                                            shipping={wooCommerceOrderDetails.shipping_total}
                                            shippingTax={wooCommerceOrderDetails.shipping_tax}
                                            cartTax={wooCommerceOrderDetails.cart_tax}
                                            total={wooCommerceOrderDetails.total}
                                            totalTax={wooCommerceOrderDetails.total_tax}
                                            pricesIncludeTax={wooCommerceOrderDetails.prices_include_tax}
                                            payment_method={wooCommerceOrderDetails.payment_method}
                                            payment_method_title={wooCommerceOrderDetails.payment_method_title}
                                            shipping_lines={wooCommerceOrderDetails?.shipping_lines}
                                        />
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </MDBox>
                    </Card>
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default OrderDetails;
