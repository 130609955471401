import React, { useEffect, memo } from 'react';
import { useQuery } from 'react-query';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

import { getChat } from 'services/api/general/chat';
import { getFiles } from 'services/api/general/file';

import MDBadge from 'components/MDBadge';

const fetchTodoMessages = async (relatedID) => {
    const response = await getChat(relatedID).then((rp) => rp.data.result);
    return response;
};
const fetchTodoFiles = async (relatedID) => {
    const response = await getFiles(relatedID).then((rp) => rp.data.result);
    return response;
};

export default function TodoSummary(props) {
    const { taskID, completed, setLoading } = props;

    return (
        <MDBox display="flex">
            <MDBox mr={1}>
                <GetLatestChatMessage taskID={taskID} completed={completed} setLoading={setLoading} />
            </MDBox>
            <MDBox>
                <GetFiles taskID={taskID} completed={completed} />
            </MDBox>
        </MDBox>
    );
}
const GetLatestChatMessage = memo((props) => {
    const { taskID, completed, setLoading } = props;

    const { data, isLoading, error, isFetching } = useQuery(
        ['fetchChatMessages', taskID],
        () => fetchTodoMessages(taskID),
        { staleTime: 60 * 1000 }
    );
    useEffect(() => {
        setLoading(true);
    }, [setLoading]);

    useEffect(() => {
        // Gecikme süresini 2000 milisaniye (2 saniye) olarak ayarlıyoruz
        const timer = setTimeout(() => {
            setLoading(false);
        }, 500);

        // Eğer bu useEffect'in bağımlılıkları güncellenirse veya component unmount edilirse, timer'ı iptal ediyoruz.
        return () => clearTimeout(timer);
    }, [data, setLoading]);
    if (isLoading) return '';

    if (isFetching) return '';

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    return (
        data &&
        data.length > 0 && (
            <MDBadge
                badgeContent={data.length + ' mesaj'}
                size="xs"
                container
                color={completed ? 'secondary' : 'info'}
            />
        )
    );
});

const GetFiles = memo((props) => {
    const { taskID, completed } = props;

    const { data, isLoading, error, isFetching } = useQuery(['fetchTodoFiles', taskID], () => fetchTodoFiles(taskID), {
        staleTime: 60 * 1000,
    });

    if (isLoading) return '';

    if (isFetching) return '';

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    return data && data.length > 0 ? (
        <MDBadge
            badgeContent={data.length + ' dosya'}
            size="xs"
            container
            color={completed ? 'secondary' : 'warning'}
        />
    ) : (
        ''
    );
});
