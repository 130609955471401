import axios from 'axios';
import { config } from 'Constants';

export const getArasShipments = async (startDate, endDate) => {
    try {
        const url = `${config.url.API_URL}/api/shipments/retrieve-shippings`;
        const { data } = await axios.post(
            url,
            {
                startingDate: startDate,
                finishingDate: endDate,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: JSON.parse(localStorage.getItem('user')).token,
                },
            }
        );

        if (!data.success) {
            throw new Error(data.message || 'Veri alınamadı');
        }

        return Array.isArray(data.shippings) ? data.shippings : [];
    } catch (error) {
        console.error('Aras kargo gönderileri alınırken hata:', error);
        throw new Error(error.response?.data?.message || error.message || 'Veriler alınamadı');
    }
};
