import React, { useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box, Typography, TextField, Alert, Tooltip } from '@mui/material';
import { useQuery } from 'react-query';
import { getArasShipments } from 'services/api/general/shipments';
import { format, subDays } from 'date-fns';
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Snackbar } from '@mui/material';
import MDBox from 'components/MDBox';
import { GridToolbar } from '@mui/x-data-grid-pro';

const ArasShipments = () => {
    const [dateRange, setDateRange] = useState({
        startDate: format(subDays(new Date(), 14), 'yyyy-MM-dd'),
        endDate: format(new Date(), 'yyyy-MM-dd'),
    });

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    });

    const {
        data: shipments = [],
        isLoading,
        error,
        isError,
    } = useQuery({
        queryKey: ['arasShipments', dateRange.startDate, dateRange.endDate],
        queryFn: () => getArasShipments(dateRange.startDate, dateRange.endDate),
        enabled: !!dateRange.startDate && !!dateRange.endDate,
        retry: 1,
    });

    const columns = [
        {
            field: 'KARGO_TAKIP_NO',
            headerName: 'Takip No',
            flex: 1.3,
            minWidth: 200,
            renderCell: (params) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        cursor: 'pointer',
                        '&:hover': {
                            color: 'primary.main',
                        },
                    }}
                    onClick={() => {
                        navigator.clipboard.writeText(params.value);
                        setSnackbar({
                            open: true,
                            message: 'Takip numarası kopyalandı',
                        });
                    }}
                >
                    {params.value}
                    <ContentCopyIcon fontSize="small" />
                </Box>
            ),
        },
        {
            field: 'ALICI',
            headerName: 'Alıcı',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'TUTAR',
            headerName: 'Tutar',
            flex: 0.8,
            minWidth: 120,
            type: 'number',
            valueFormatter: (params) => {
                return params.value ? `${params.value} TL` : '';
            },
        },
        {
            field: 'KARGO_DETAY',
            headerName: 'Kargo Detayı',
            flex: 1,
            minWidth: 200,
            valueGetter: (params) => {
                const adet = params.row.ADET || '0';
                const desi = params.row.DESI || '0';
                return `${adet} Adet / ${desi} Desi`;
            },
        },
        {
            field: 'ODEME_TIPI',
            headerName: 'Ödeme Tipi',
            flex: 0.8,
            minWidth: 120,
        },
        {
            field: 'IRSALIYE_NUMARA',
            headerName: 'İrsaliye No',
            flex: 0.8,
            minWidth: 120,
        },
        {
            field: 'GONDERICI',
            headerName: 'Gönderici',
            flex: 1,
            minWidth: 150,
            valueFormatter: (params) => params.value?.split('//')[0] || params.value,
        },

        {
            field: 'DURUMU',
            headerName: 'Durum',
            flex: 1,
            renderCell: (params) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    {params.value}
                    {params.row.IADE_SEBEBI && (
                        <Tooltip title={params.row.IADE_SEBEBI}>
                            <InfoIcon color="warning" fontSize="small" />
                        </Tooltip>
                    )}
                </Box>
            ),
        },
        {
            field: 'CIKIS_TARIH',
            headerName: 'Çıkış Tarihi',
            flex: 0.8,
            minWidth: 160,
            valueFormatter: (params) => {
                return params.value ? new Date(params.value).toLocaleString('tr-TR') : '';
            },
        },
        {
            field: 'SUBE_BILGISI',
            headerName: 'Şube Bilgisi',
            flex: 1,
            minWidth: 200,
            valueGetter: (params) => `${params.row.CIKIS_SUBE} → ${params.row.VARIS_SUBE}`,
        },

        {
            field: 'REFERANS',
            headerName: 'Referans',
            flex: 0.8,
            minWidth: 120,
        },
    ];

    return (
        <MDBox sx={{ height: 600, width: '100%' }}>
            <MDBox sx={{ mb: 2, display: 'flex', gap: 2 }}>
                <TextField
                    label="Başlangıç Tarihi"
                    type="date"
                    value={dateRange.startDate}
                    onChange={(e) =>
                        setDateRange((prev) => ({
                            ...prev,
                            startDate: e.target.value,
                        }))
                    }
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Bitiş Tarihi"
                    type="date"
                    value={dateRange.endDate}
                    onChange={(e) =>
                        setDateRange((prev) => ({
                            ...prev,
                            endDate: e.target.value,
                        }))
                    }
                    InputLabelProps={{ shrink: true }}
                />
            </MDBox>
            {isError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error?.message || 'Veriler yüklenirken bir hata oluştu'}
                </Alert>
            )}
            {!isError && shipments.length === 0 && !isLoading && (
                <Alert severity="info" sx={{ mb: 2 }}>
                    Seçilen tarih aralığında gönderi bulunamadı
                </Alert>
            )}
            <DataGridPro
                rows={shipments}
                columns={columns}
                loading={isLoading}
                pagination
                paginationModel={{ page: 0, pageSize: 10 }}
                pageSizeOptions={[10, 25, 50]}
                checkboxSelection
                disableRowSelectionOnClick
                localeText={{
                    noRowsLabel: 'Gönderi bulunamadı',
                    footerRowSelected: (count) => `${count} gönderi seçildi`,
                }}
                getRowId={(row) => row.KARGO_TAKIP_NO || Math.random().toString(36).substr(2, 9)}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'CIKIS_TARIH', sort: 'desc' }],
                    },
                    filter: {
                        filterModel: {
                            items: [],
                        },
                    },
                }}
                disableColumnFilter
                components={{
                    Toolbar: GridToolbar,
                }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </MDBox>
    );
};

export default ArasShipments;
