import React from 'react';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 19,
    height: 19,
    border: `1px solid ${theme.palette.background.paper}`,
}));

export default function ListAvatar({ taskData, createdBy, isFavorite, isSelectedList }) {
    let color = 'linear-gradient(310deg, #747b8a, #495361)'; // Eğer task yoksa, gri tonunda olacak
    let today = new Date();
    const user = JSON.parse(localStorage.getItem('user'));
    const isSameUser = user.userID === createdBy._id;
    if (taskData.length > 0) {
        const uncompletedTasks = taskData.filter((task) => !task.completed);

        if (uncompletedTasks.length > 0) {
            const overdueTasks = uncompletedTasks.filter(
                (task) => task.due && task.due.dateTime !== null && new Date(task.due.dateTime) < today
            );
            const upcomingTasks = uncompletedTasks.filter((task) => {
                if (task.due && task.due.dateTime !== null) {
                    const taskDueDate = new Date(task.due.dateTime);
                    const timeDifference = taskDueDate - today; // taskDueDate ve today arasındaki milisaniye cinsinden zaman farkı

                    const timeDifferenceInHours = timeDifference / (1000 * 60 * 60); // zaman farkını saat cinsine çevir

                    return timeDifferenceInHours >= 0 && timeDifferenceInHours <= 48; // Eğer 0 ile 48 saat arasında ise, bu bir "yaklaşan" görevdir
                }
                return false; // Eğer due.dateTime null ise veya geçmiş bir tarih ise, bu bir "yaklaşan" görev değildir
            });

            // due date'i geçmiş veya yaklaşan tasklar varsa
            if (overdueTasks.length > 0) {
                color = 'linear-gradient(310deg, #EC407A, #D81B60)';
            } else if (upcomingTasks.length > 0) {
                color = 'linear-gradient(310deg, #EC407A, #D81B60)';
            } else {
                // Eğer herhangi bir due date'i geçmiş veya yaklaşan task yoksa
                // createdAt'a göre bir renk tonu seçelim
                let oldestTask = uncompletedTasks.reduce((oldest, current) => {
                    return new Date(oldest.createdAt) < new Date(current.createdAt) ? oldest : current;
                });

                let daysOld = Math.floor((today - new Date(oldestTask.createdAt)) / (1000 * 60 * 60 * 24));

                // Oldest task'a göre bir mavi tonu seç
                color =
                    daysOld > 7
                        ? 'linear-gradient(310deg, #49a3f1, #1A73E8)'
                        : 'linear-gradient(310deg, #49a3f1, #1A73E8)';
            }
        } else {
            // Bütün tasklar tamamlanmış ise
            color = 'linear-gradient(310deg, #66BB6A, #43A047)';
        }
    }

    return isSameUser ? (
        <div style={{ position: 'relative' }}>
            <Avatar
                sx={{
                    backgroundImage: color,
                    // border: isSelectedList && '1px solid #f5af19',
                    // position: 'relative',
                    // '&::before': isFavorite
                    //     ? {
                    //           content: '""',
                    //           position: 'absolute',
                    //           top: 0,
                    //           left: 0,
                    //           borderTop: '9px solid #ff0084',
                    //           borderRight: '9px solid transparent',
                    //           borderBottom: '0 solid transparent',
                    //           borderLeft: '0 solid transparent',
                    //           clipPath: 'polygon(0 0, 100% 0, 0 100%)', // Üçgen şeklini belirlemek için
                    //       }
                    //     : {},
                }}
                variant="rounded"
            >
                {taskData.length}
            </Avatar>
        </div>
    ) : (
        <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
                <Tooltip title={createdBy.username} placement="right">
                    <SmallAvatar alt={createdBy.username} src={createdBy?.profilePicture} />
                </Tooltip>
            }
        >
            <Avatar
                sx={{
                    backgroundImage: color,
                    // border: isSelectedList && '1px solid #f5af19'
                }}
                variant="rounded"
            >
                {taskData.length}
            </Avatar>
        </Badge>
    );
}
