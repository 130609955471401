import React from 'react';
import Switch from '@mui/material/Switch';
import { AllPurchasesContext } from 'layouts/purchases/pages/allLeads/index.js';
import { FormControlLabel } from '@mui/material';

export default function ViewSwitch() {
    const { showShipments, setShowShipments } = React.useContext(AllPurchasesContext);

    return (
        <FormControlLabel
            control={<Switch checked={showShipments} onChange={() => setShowShipments(!showShipments)} />}
            label="Kargo Takip"
        />
    );
}
