/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { DataGridPro, GridToolbar, LicenseInfo, useGridApiRef, gridClasses } from '@mui/x-data-grid-pro';
import { useQueryClient, useQuery } from 'react-query';
import useDialogHook from 'hooks/useDialogHook';

import { ThemeProvider } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
// Core elements
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import Modal from '@mui/material/Modal';
import Avatar from '@mui/material/Avatar';
import { TableFooter, TableCell, TableRow } from '@mui/material';
import MuiLink from '@mui/material/Link';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EventNoteIcon from '@mui/icons-material/EventNote';
import HistoryIcon from '@mui/icons-material/History';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import GoogleIcon from '@mui/icons-material/Google';

// forms
import { UKasa } from 'layouts/projects/Forms/UKasa/UKasaForm';
import { Kasa } from 'layouts/projects/Forms/Kasa/KasaForm';
import { Kanat } from 'layouts/projects/Forms/Kanat/KanatForm';
import { UPervaz } from 'layouts/projects/Forms/UPervaz/UPervazForm';
import { Pervaz } from 'layouts/projects/Forms/Pervaz/PervazForm';
import { Diger } from 'layouts/projects/Forms/Diger/DigerForm';
import { updateDiger, updateKanat, updateKasa, updatePervaz, updateUKasa, updateUPervaz } from 'services/api/form';
import { deleteUKasa, deleteKasa, deleteKanat, deleteUPervaz, deletePervaz, deleteDiger } from 'services/api/form';
import { copyUKasa, copyKasa, copyKanat, copyUPervaz, copyPervaz, copyDiger } from 'services/api/form';
import { updateDemand } from 'services/api/project';
// form summaries
// import KasaGrid from 'layouts/projects/Forms/Kasa/KasaGrid';
// import UKasaGrid from 'layouts/projects/Forms/UKasa/UKasaGrid';
// import PervazGrid from 'layouts/projects/Forms/Pervaz/PervazGrid';
// import UPervazGrid from 'layouts/projects/Forms/UPervaz/UPervazGrid';
// import KanatGrid from 'layouts/projects/Forms/Kanat/KanatGrid';
import Comparision from 'layouts/projects/Forms/Comparision';

// form histories
import FormHistory from 'layouts/projects/Forms/FormHistoy';

// helpers
import { calculateDiscount, formatPrice } from 'helpers/priceHelpers';
import { getDemandStatusColor } from 'helpers/colorHelpers';
import { notify } from 'helpers/notificationHelper';

import pxToRem from 'assets/theme/functions/pxToRem.js';

import { getTags } from 'services/api/general/tag';
import { config } from 'Constants';
import AaroLoadingScreen from 'components/Loading';
import { lineImage, renderCellExpand, demandNamesWeWant, richTextTheme, useStyles, getModalStyle } from './basicHelper';

LicenseInfo.setLicenseKey(
    'ae1903380024f5c5b97717d643ea7d59Tz01NDE5NyxFPTE2OTk3NzcxODU3ODUsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
);

const fetchTags = async (id) => {
    const response = await getTags(id).then((rp) => (rp.data?.result?.tags ? rp.data?.result?.tags : []));
    return response;
};
const TAG_GROUP_ID_DEMAND = config.fixedID.DEMAND_STATUS_TAG_GROUP_ID;
export default function RenderCellGrid(props) {
    const { projectID, demand, projectName } = props;
    const { open: openComparision, handleClickOpen, handleClose } = useDialogHook();
    const queryClient = useQueryClient();
    const apiRef = useGridApiRef();
    const user = JSON.parse(localStorage.getItem('user'));
    const [expandRows, setExpandRows] = useState(true);
    const [rowsUpdated, setRowsUpdated] = useState(false);
    const [rowVals, setRowVals] = useState([]);
    const [modalStyle] = React.useState(getModalStyle);

    const [open, setOpen] = React.useState(false);
    const [openHistory, setOpenHistory] = React.useState(false);
    // const [tags, setTags] = useState(['']);
    const styles = useStyles();
    const [activeItemID, setActiveItemID] = React.useState('');
    const [activeItemName, setActiveItemName] = React.useState('');
    const [activeItemData, setActiveItemData] = React.useState({});
    const [notes, setNotes] = React.useState('');

    const {
        data: tags,
        isLoading,
        error,
    } = useQuery(['fetchTagGroups', TAG_GROUP_ID_DEMAND], () => fetchTags(TAG_GROUP_ID_DEMAND), { staleTime: 600000 });

    // * Edit cells section
    const updateCell = React.useCallback((type, id, obj) => {
        if (type === 'kanat')
            updateKanat(id, obj)
                .then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    queryClient.invalidateQueries(['fetchDemand', demand._id]);
                })
                .catch((err) => {
                    notify(false, err.message);
                });
        else if (type === 'diger')
            updateDiger(id, obj)
                .then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    queryClient.invalidateQueries(['fetchDemand', demand._id]);
                })
                .catch((err) => {
                    notify(false, err.message);
                });
        else if (type === 'kasa')
            updateKasa(id, obj)
                .then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    queryClient.invalidateQueries(['fetchDemand', demand._id]);
                })
                .catch((err) => {
                    notify(false, err.message);
                });
        else if (type === 'uKasa')
            updateUKasa(id, obj)
                .then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    queryClient.invalidateQueries(['fetchDemand', demand._id]);
                })
                .catch((err) => {
                    notify(false, err.message);
                });
        else if (type === 'pervaz')
            updatePervaz(id, obj)
                .then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    queryClient.invalidateQueries(['fetchDemand', demand._id]);
                })
                .catch((err) => {
                    notify(false, err.message);
                });
        else if (type === 'uPervaz')
            updateUPervaz(id, obj)
                .then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    queryClient.invalidateQueries(['fetchDemand', demand._id]);
                })
                .catch((err) => {
                    notify(false, err.message);
                });
    }, []);
    const handleRowEditCommit = React.useCallback(
        (params) => {
            const id = params.id.split('-')[0];
            const type = params.id.split('-')[1];
            const key = params.field;
            const value = params.value;
            let obj = {};
            obj[key] = value;
            if (key === 'durumu') {
                const findTagID = tags.find((tag) => tag.name === value);
                obj[key] = findTagID._id;
                updateCell(type, id, obj);
            } else {
                updateCell(type, id, obj);
            }
        },
        [tags]
    );

    // Row Spacing
    const getRowSpacing = React.useCallback((params) => {
        return {
            top: params.isFirstVisible ? 0 : 1,
            bottom: params.isLastVisible ? 0 : 1,
        };
    }, []);

    useEffect(() => {
        let rowValues = [];
        const allDemandItems = demand;
        let isEmptyCounter = 0;
        Object.keys(allDemandItems).forEach((demandName) => {
            if (demandNamesWeWant.includes(demandName)) {
                // array demands arrayi keyleri birden fazlaysa rowlar boş değildir
                isEmptyCounter = isEmptyCounter + allDemandItems[demandName].length;
                for (let j = 0; j < allDemandItems[demandName].length; j++) {
                    const singleDemandItem = allDemandItems[demandName][j];
                    // console.log(singleDemandItem);
                    let googleSheetURL = '';
                    if (demand.activeSheet) {
                        const { matches, spreadsheetId } = demand.activeSheet;
                        const match = matches.find((el) => el.productID === singleDemandItem._id);
                        googleSheetURL = `https://docs.google.com/spreadsheets/d/${spreadsheetId}/edit#gid=${match?.sheetId}`;
                    }

                    const rowValue = {
                        id: `${singleDemandItem._id}-${demandName}`,
                        googleSheetURL: googleSheetURL,
                        talepFormununAdi: demandName,
                        yoneticiNotu: singleDemandItem.yoneticiNotu,
                        en: singleDemandItem.en,
                        boy: singleDemandItem.boy,
                        kalinlik: singleDemandItem.kalinlik,
                        gorsel: singleDemandItem.gorsel ? singleDemandItem.gorsel : lineImage(demandName),
                        baslik: singleDemandItem.baslik,
                        ekNot: singleDemandItem.ekNot,
                        durumu: singleDemandItem?.durumu?.name,
                        verilenFiyat: singleDemandItem.verilenFiyat,
                        miktar: singleDemandItem.miktar,
                        indirimOrani: singleDemandItem.indirimOrani ? singleDemandItem.indirimOrani : 0,
                        toplam: 0,
                        musteriNotu: singleDemandItem.musteriNotu ? singleDemandItem.musteriNotu : '',
                        rowFullData: singleDemandItem,
                    };

                    rowValues.push(rowValue);
                    setRowVals(rowValues);
                    setRowsUpdated(true);
                }
            }
        });
        if (isEmptyCounter < 1) setRowsUpdated(false);

        const sampleMarkup = demand?.demandNotes ? demand?.demandNotes : '';

        let x = JSON.parse(sampleMarkup);
        setNotes(JSON.stringify(x));
    }, [props]);

    // * Demand note updates
    const updateDemandNote = (val) => {
        updateDemand(demand._id, { demandNotes: val })
            .then((rp) => notify(rp.data.success, rp.data.message))
            .catch((e) => notify(false, e.message));
    };

    // * Form Modals
    const updateItem = (activeItemType, id, data) => {
        if (activeItemType === 'kanat')
            return <Kanat initial={data} id={id} setOpen={setOpen} projectID={projectID} demandID={demand._id} />;
        else if (activeItemType === 'diger')
            return <Diger initial={data} id={id} setOpen={setOpen} projectID={projectID} demandID={demand._id} />;
        else if (activeItemType === 'kasa')
            return <Kasa initial={data} id={id} setOpen={setOpen} projectID={projectID} demandID={demand._id} />;
        else if (activeItemType === 'uKasa')
            return <UKasa initial={data} id={id} setOpen={setOpen} projectID={projectID} demandID={demand._id} />;
        else if (activeItemType === 'pervaz')
            return <Pervaz initial={data} id={id} setOpen={setOpen} projectID={projectID} demandID={demand._id} />;
        else if (activeItemType === 'uPervaz')
            return <UPervaz initial={data} id={id} setOpen={setOpen} projectID={projectID} demandID={demand._id} />;
    };

    // ! ITEM DELETION
    const deleteItem = (activeItemType, id) => {
        if (activeItemType === 'kanat') {
            const r = window.confirm('Emin Misiniz?');
            if (r == true) {
                deleteKanat(id)
                    .then((rp) => {
                        notify(rp.data.success, rp.data.message);

                        queryClient.invalidateQueries(['fetchDemand', demand._id]);
                    })
                    .catch((err) => {
                        notify(false, err.message);
                    });
            }
        } else if (activeItemType === 'diger') {
            const r = window.confirm('Emin Misiniz?');
            if (r == true) {
                deleteDiger(id)
                    .then((rp) => {
                        notify(rp.data.success, rp.data.message);

                        queryClient.invalidateQueries(['fetchDemand', demand._id]);
                    })
                    .catch((err) => {
                        notify(false, err.message);
                    });
            }
        } else if (activeItemType === 'kasa') {
            const r = window.confirm('Emin Misiniz?');
            if (r == true) {
                deleteKasa(id)
                    .then((rp) => {
                        notify(rp.data.success, rp.data.message);

                        queryClient.invalidateQueries(['fetchDemand', demand._id]);
                    })
                    .catch((err) => {
                        notify(false, err.message);
                    });
            }
        } else if (activeItemType === 'uKasa') {
            const r = window.confirm('Emin Misiniz?');
            if (r == true) {
                deleteUKasa(id)
                    .then((rp) => {
                        notify(rp.data.success, rp.data.message);

                        queryClient.invalidateQueries(['fetchDemand', demand._id]);
                    })
                    .catch((err) => {
                        notify(false, err.message);
                    });
            }
        } else if (activeItemType === 'pervaz') {
            const r = window.confirm('Emin Misiniz?');
            if (r == true) {
                deletePervaz(id)
                    .then((rp) => {
                        notify(rp.data.success, rp.data.message);

                        queryClient.invalidateQueries(['fetchDemand', demand._id]);
                    })
                    .catch((err) => {
                        notify(false, err.message);
                    });
            }
        } else if (activeItemType === 'uPervaz') {
            const r = window.confirm('Emin Misiniz?');
            if (r == true) {
                deleteUPervaz(id)
                    .then((rp) => {
                        notify(rp.data.success, rp.data.message);

                        queryClient.invalidateQueries(['fetchDemand', demand._id]);
                    })
                    .catch((err) => {
                        notify(false, err.message);
                    });
            }
        }
    };
    // ! ITEM COP
    const copyItem = (activeItemType, id, demandID) => {
        if (activeItemType === 'kanat') {
            const r = window.confirm('Emin Misiniz?');
            if (r == true) {
                copyKanat(id, demandID)
                    .then(() => {
                        queryClient.invalidateQueries(['fetchDemand', demand._id]);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        } else if (activeItemType === 'diger') {
            const r = window.confirm('Kopyalamak istediğinize emin misiniz?');
            if (r == true) {
                copyDiger(id, demandID)
                    .then(() => {
                        queryClient.invalidateQueries(['fetchDemand', demand._id]);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        } else if (activeItemType === 'kasa') {
            const r = window.confirm('Emin Misiniz?');
            if (r == true) {
                copyKasa(id, demandID)
                    .then(() => {
                        queryClient.invalidateQueries(['fetchDemand', demand._id]);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        } else if (activeItemType === 'uKasa') {
            const r = window.confirm('Emin Misiniz?');
            if (r == true) {
                copyUKasa(id, demandID)
                    .then(() => {
                        queryClient.invalidateQueries(['fetchDemand', demand._id]);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        } else if (activeItemType === 'pervaz') {
            const r = window.confirm('Emin Misiniz?');
            if (r == true) {
                copyPervaz(id, demandID)
                    .then(() => {
                        queryClient.invalidateQueries(['fetchDemand', demand._id]);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        } else if (activeItemType === 'uPervaz') {
            const r = window.confirm('Emin Misiniz?');
            if (r == true) {
                copyUPervaz(id, demandID)
                    .then(() => {
                        queryClient.invalidateQueries(['fetchDemand', demand._id]);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    };

    // *  Grid History
    const openGridHistory = (activeItemType, id, data) => {
        if (activeItemType === 'uKasa') return <FormHistory formName="uKasa" id={id} />;
        if (activeItemType === 'kasa') return <FormHistory formName="kasa" id={id} />;
        if (activeItemType === 'pervaz') return <FormHistory formName="pervaz" id={id} />;
        if (activeItemType === 'uPervaz') return <FormHistory formName="uPervaz" id={id} />;
        if (activeItemType === 'kanat') return <FormHistory formName="kanat" id={id} />;
        if (activeItemType === 'diger') return <FormHistory formName="diger" id={id} />;
    };
    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    // * Columns
    const columns = [
        // * google sheet url
        {
            field: 'googleSheetURL',
            headerName: 'Google',

            flex: 15,
            renderCell: (params) => (
                <MuiLink href={params.formattedValue} target="_blank" rel="noreferrer">
                    {params.formattedValue.length > 0 ? (
                        <GoogleIcon fontSize="small" sx={{ color: '#34A853' }} />
                    ) : (
                        <PowerOffIcon fontSize="small" />
                    )}
                </MuiLink>
            ),
            editable: false,
            hide: true,
        },
        // * gorsel
        {
            field: 'gorsel',
            headerName: 'Görsel',
            flex: 25,
            renderCell: (params) => (
                <strong>
                    <Avatar
                        alt="Kasa"
                        variant="rounded"
                        src={
                            params.formattedValue
                                ? params.formattedValue
                                : 'https://www.kahramanlar.com.tr/wp-content/uploads/2017/08/deneme1-1.png'
                        }
                        style={{
                            // marginLeft: 16,
                            width: '40px',
                            height: '40px',
                        }}
                    />
                </strong>
            ),
            editable: true,
        },

        // * Düzenle
        // params.id.split('-')[0] 615f591c82e75f6518fc41db-diger
        {
            field: 'edit',
            headerName: 'Düzenle',
            flex: 50,
            renderCell: (params) => (
                <>
                    <Stack direction="row" spacing={1} sx={{ width: 1, py: 1 }}>
                        <Stack spacing={1} sx={{ width: 1, py: 1 }}>
                            <React.Fragment>
                                <IconButton
                                    aria-label="edit"
                                    size="small"
                                    onClick={() => {
                                        setOpen(true);
                                        setActiveItemName(params.row.talepFormununAdi);
                                        setActiveItemData(params.row.rowFullData);
                                        setActiveItemID(params.id.split('-')[0]);
                                    }}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                    aria-label="delete"
                                    size="small"
                                    onClick={() => {
                                        deleteItem(
                                            params.row.talepFormununAdi,
                                            params.id.split('-')[0],
                                            queryClient,
                                            demand._id
                                        );
                                    }}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                    aria-label="delete"
                                    size="small"
                                    onClick={() => {
                                        copyItem(params.row.talepFormununAdi, params.id.split('-')[0], demand._id);
                                    }}
                                >
                                    <ContentCopyIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        </Stack>
                        <Stack spacing={1} sx={{ width: 1, py: 1 }}>
                            <React.Fragment>
                                <IconButton
                                    aria-label="delete"
                                    size="small"
                                    onClick={() => {
                                        setOpenHistory(true);
                                        setActiveItemName(params.row.talepFormununAdi);
                                        setActiveItemData(params.row.rowFullData);
                                        setActiveItemID(params.id.split('-')[0]);
                                    }}
                                >
                                    <HistoryIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                    aria-label="edit2"
                                    size="small"
                                    onClick={() => {
                                        handleClickOpen();
                                        setActiveItemName(params.row.talepFormununAdi);
                                        setActiveItemData(params.row.rowFullData);
                                        setActiveItemID(params.id.split('-')[0]);
                                    }}
                                >
                                    <EventNoteIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        </Stack>
                    </Stack>
                </>
            ),
            editable: false,
        },

        // * durumu
        {
            field: 'durumu',
            headerName: 'Durumu',
            flex: 50,
            type: 'singleSelect',
            valueOptions: tags.map((el) => el.name),
            renderCell: (params) => (
                <MDButton variant="gradient" size="small" color={getDemandStatusColor(params.formattedValue)} fullWidth>
                    {params.formattedValue}
                </MDButton>
            ),
            editable: true,
        },
        // * Yönetici Notu
        {
            field: 'yoneticiNotu',
            headerName: 'Yönetici Notu',
            flex: 25,
            renderCell: renderCellExpand,
            editable: user.role == 'superadmin' ? true : false,
        },

        // * başlık
        {
            field: 'baslik',
            headerName: 'Başlık',
            flex: 150,
            editable: false,
        },

        // * ek not
        {
            field: 'ekNot',
            headerName: 'Ek Not',
            type: 'string',
            flex: 25,
            renderCell: renderCellExpand,
        },

        // * kalınlık
        {
            field: 'kalinlik',
            headerName: 'Kalınlık (cm)',
            flex: 50,
            type: 'number',
            editable: false,
            hide: true,
        },
        // * en
        {
            field: 'en',
            headerName: 'En (cm)',
            flex: 50,
            type: 'number',
            editable: false,
            hide: true,
        },
        // * boy
        {
            field: 'boy',
            headerName: 'Boy (cm)',
            flex: 50,
            type: 'number',
            editable: false,
            hide: true,
        },
        // * miktar
        {
            field: 'miktar',
            headerName: 'Miktar',
            flex: 50,
            type: 'number',
            editable: true,
        },
        // * birim fiyatı
        {
            field: 'verilenFiyat',
            headerName: 'Birim Fiyatı',
            flex: 50,
            type: 'number',
            valueGetter: (params) => {
                const valueFormatted = formatPrice(
                    calculateDiscount(params.row.verilenFiyat, 1, params.row.indirimOrani),
                    demand.demandCurrency ? demand.demandCurrency : 'TRY'
                );
                return `${valueFormatted}`;
            },

            editable: user.role == 'superadmin' ? true : false,
        },
        // * indirimOrani
        {
            field: 'indirimOrani',
            headerName: 'İndirim',
            flex: 50,
            type: 'number',
            valueGetter: (params) => {
                const valueFormatted = Number(params.value).toLocaleString();
                return `${valueFormatted} %`;
            },

            editable: true,
        },
        // * toplamı
        {
            field: 'toplam',
            headerName: 'Toplam',
            flex: 80,
            type: 'number',
            valueGetter: (params) => {
                const valueFormatted = formatPrice(
                    calculateDiscount(params.row.verilenFiyat, params.row.miktar, params.row.indirimOrani),
                    demand.demandCurrency ? demand.demandCurrency : 'TRY'
                );
                return `${valueFormatted}`;
            },

            editable: false,
        },

        // * musteriNotu
        {
            field: 'musteriNotu',
            headerName: 'Müşterimize Notumuz',
            flex: 150,
            type: 'string',
            editable: true,
            hide: true,
        },
    ];

    // * Calculate Total Sum
    const SumOfGridRows = () => {
        const rows = apiRef.current.getVisibleRowModels();
        let total = 0;
        for (const cur of rows.values()) {
            total += calculateDiscount(cur.verilenFiyat, cur.miktar, cur.indirimOrani);
        }
        return formatPrice(total, demand.demandCurrency ? demand.demandCurrency : 'TRY');
    };

    return (
        <MDBox>
            {rowsUpdated ? (
                <MDBox color="white" bgColor="white" variant="gradient" borderRadius="lg" shadow="lg" opacity={1} p={2}>
                    <DataGridPro
                        autoHeight
                        autoPageSize
                        // checkboxSelection
                        disableSelectionOnClick
                        // density="compact"
                        rows={rowVals}
                        columns={columns}
                        // localeText={trTR.props.MuiDataGrid.localeText}
                        onCellEditCommit={handleRowEditCommit}
                        // onEditRowsModelChange={handleEditRowsModelChange}
                        apiRef={apiRef}
                        // initialState={{ pinnedColumns: { left: [], right: ['miktar','verilenFiyat','indirimOrani','toplam'] } }}
                        // rowReordering
                        sx={{
                            fontSize: pxToRem(12),
                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: 400,
                            lineHeight: 1.25,
                            [`& .${gridClasses.row}`]: {
                                bgcolor: (theme) => (theme.palette.mode === 'light' ? grey[50] : grey[50]),
                            },
                        }}
                        getRowHeight={() => (expandRows ? 'auto' : null)}
                        getRowSpacing={getRowSpacing}
                        components={{
                            Toolbar: GridToolbar,
                            Footer: () => (
                                <MDBox>
                                    <TableFooter
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row-reverse',
                                        }}
                                    >
                                        <TableRow>
                                            <TableCell colSpan={12}>
                                                <strong>
                                                    {' '}
                                                    <p
                                                        style={{
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {' '}
                                                        Toplam: {SumOfGridRows()}
                                                    </p>
                                                </strong>
                                                {/* {sumColumnPrices(
						                            startIndex,
						                            endIndex
						                        )} */}
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>

                                    <MDBox>
                                        <Button onClick={() => setExpandRows(!expandRows)}>
                                            Satırları {expandRows ? 'daralt' : 'genişlet'}
                                        </Button>
                                    </MDBox>
                                </MDBox>
                            ),
                        }}
                    />
                    <Modal open={open} onClose={() => setOpen(false)}>
                        <div style={modalStyle} className={styles.paper}>
                            {updateItem(activeItemName, activeItemID, activeItemData)}
                        </div>
                    </Modal>

                    {openComparision && (
                        <Comparision
                            activeItemType={activeItemName}
                            initial={activeItemData}
                            id={activeItemID}
                            open={openComparision}
                            handleClose={handleClose}
                            projectName={projectName}
                            projectID={projectID}
                        />
                    )}
                    <Modal open={openHistory} onClose={() => setOpenHistory(false)}>
                        <div style={modalStyle} className={styles.paper}>
                            {openGridHistory(activeItemName, activeItemID, activeItemData)}
                        </div>
                    </Modal>
                </MDBox>
            ) : (
                <MDBox bgColor="white" variant="gradient">
                    <MDTypography variant="h6" fontWeight="medium">
                        Projeye kalem girilmemiş
                    </MDTypography>
                </MDBox>
            )}
        </MDBox>
    );
}
