import { useState } from 'react';
import moment from 'moment';
import { useQueryClient } from 'react-query';

import { notify } from 'helpers/notificationHelper';

// @mui material components
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

// Images
import logoSMS from 'assets/images/small-logos/sms.png';
import logoGmail from 'assets/images/small-logos/gmail.png';
import logoSlackPNG from 'assets/images/small-logos/slack.png';
import logoWhatsapp from 'assets/images/small-logos/whatsapp.png';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Stack from '@mui/material/Stack';
import trLocale from 'date-fns/locale/tr';
// Material Dashboard 2 PRO React components
import { postReminder } from 'services/api/general/reminder';

export default function AddNote({ relatedID, title, message, userIDs }) {
    const user = JSON.parse(localStorage.getItem('user'));
    const queryClient = useQueryClient();
    const [value, setValue] = useState(new Date());
    const [slack, setSlack] = useState(true);
    const [mail, setMail] = useState(true);
    const [sms, setSMS] = useState(true);
    const [whatsapp, setWhatsapp] = useState(false);
    const [newMessage, setNewMessage] = useState(message ? message : '');

    const saveDetails = (e) => {
        e.preventDefault();
        const SMS = sms ? 'SMS' : '';
        const MAIL = mail ? 'MAIL' : '';
        const SLACK = slack ? 'SLACK' : '';

        const data = {
            users: userIDs,
            services: [SMS, MAIL, SLACK],
            title: title ? title : user?.name + ' hatırlatıcı',
            message: newMessage,
            due: {
                date: new Date(value).toISOString().split('T')[0],
                dateTime: moment(value),
                recurring: false,
            },
            relatedID,
            executeOn: moment(value),
        };
        postReminder(data)
            .then((rp) => {
                notify(rp.data.success, rp.data.message);
                queryClient.invalidateQueries(['fetchReminders', relatedID]);

                // refetch();
            })
            .catch((err) => notify(false, JSON.stringify(err)));
    };

    return (
        <Card id="accounts">
            <form onSubmit={saveDetails}>
                <MDBox p={3} lineHeight={1}>
                    <MDBox mb={1}>
                        <MDTypography variant="h5">Hatırlatıcı Ekle</MDTypography>
                    </MDBox>
                    <MDTypography variant="button" color="text">
                        Bu görevin ne zaman ve nasıl hatırlatılmasını istersiniz.
                    </MDTypography>
                </MDBox>
                <MDBox pt={2} pb={3} px={3}>
                    <MDBox mb={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={trLocale}>
                            <Stack>
                                <DateTimePicker
                                    renderInput={(params) => <TextField {...params} />}
                                    label="Hatırlatıcı Ekleyin"
                                    value={value}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                    minDate={new Date()}
                                    // minTime={new Date(0, 0, 0, 8)}
                                    // maxTime={new Date(0, 0, 0, 18, 45)}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </MDBox>
                    <MDBox mb={3}>
                        <MDInput
                            fullWidth
                            placeholder="Hatırlatıcı Mesajı"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                        ></MDInput>
                    </MDBox>
                    <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: 'flex-start', sm: 'center' }}
                        flexDirection={{ xs: 'column', sm: 'row' }}
                    >
                        <MDBox display="flex" alignItems="center">
                            <MDAvatar src={logoSlackPNG} alt="Slack logo" variant="rounded" size="sm" />
                            <MDBox ml={2} lineHeight={0}>
                                <MDTypography variant="h5" fontWeight="medium">
                                    Slack
                                </MDTypography>
                                <MDTypography variant="button" color="text">
                                    Özel mesaj
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                        <MDBox
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            width={{ xs: '100%', sm: 'auto' }}
                            mt={{ xs: 1, sm: 0 }}
                        >
                            <MDBox lineHeight={0} mx={2}>
                                <MDTypography variant="button" color="text">
                                    {slack ? 'Aktif' : 'Pasif'}
                                </MDTypography>
                            </MDBox>
                            <MDBox mr={1}>
                                <Switch checked={slack} onChange={() => setSlack(!slack)} />
                            </MDBox>
                        </MDBox>
                    </MDBox>
                    <Divider />
                    <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: 'flex-start', sm: 'center' }}
                        flexDirection={{ xs: 'column', sm: 'row' }}
                    >
                        <MDBox display="flex" alignItems="center">
                            <MDAvatar src={logoGmail} alt="Slack logo" variant="rounded" size="sm" />
                            <MDBox ml={2} lineHeight={0}>
                                <MDTypography variant="h5" fontWeight="medium">
                                    Mail
                                </MDTypography>
                                <MDTypography variant="button" color="text">
                                    Mail ile bildirim
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                        <MDBox
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            width={{ xs: '100%', sm: 'auto' }}
                            mt={{ xs: 1, sm: 0 }}
                        >
                            <MDBox lineHeight={0} mx={2}>
                                <MDTypography variant="button" color="text">
                                    {mail ? 'Aktif' : 'Pasif'}
                                </MDTypography>
                            </MDBox>
                            <MDBox mr={1}>
                                <Switch checked={mail} onChange={() => setMail(!mail)} />
                            </MDBox>
                        </MDBox>
                    </MDBox>
                    <Divider />
                    <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: 'flex-start', sm: 'center' }}
                        flexDirection={{ xs: 'column', sm: 'row' }}
                    >
                        <MDBox display="flex" alignItems="center">
                            <MDAvatar src={logoSMS} alt="Slack logo" variant="rounded" size="sm" />
                            <MDBox ml={2} lineHeight={0}>
                                <MDTypography variant="h5" fontWeight="medium">
                                    SMS
                                </MDTypography>
                                <MDTypography variant="button" color="text">
                                    Kısa mesaj
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                        <MDBox
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            width={{ xs: '100%', sm: 'auto' }}
                            mt={{ xs: 1, sm: 0 }}
                        >
                            <MDBox lineHeight={0} mx={2}>
                                <MDTypography variant="button" color="text">
                                    {sms ? 'Aktif' : 'Pasif'}
                                </MDTypography>
                            </MDBox>
                            <MDBox mr={1}>
                                <Switch checked={sms} onChange={() => setSMS(!sms)} />
                            </MDBox>
                        </MDBox>
                    </MDBox>
                    <Divider />
                    <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: 'flex-start', sm: 'center' }}
                        flexDirection={{ xs: 'column', sm: 'row' }}
                    >
                        <MDBox display="flex" alignItems="center">
                            <MDAvatar src={logoWhatsapp} alt="Slack logo" variant="rounded" size="sm" />
                            <MDBox ml={2} lineHeight={0}>
                                <MDTypography variant="h5" fontWeight="medium">
                                    Whatsapp
                                </MDTypography>
                                <MDTypography variant="button" color="text">
                                    Henüz aktif değil
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                        <MDBox
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            width={{ xs: '100%', sm: 'auto' }}
                            mt={{ xs: 1, sm: 0 }}
                        >
                            <MDBox lineHeight={0} mx={2}>
                                <MDTypography variant="button" color="text">
                                    {whatsapp ? 'Aktif' : 'Pasif'}
                                </MDTypography>
                            </MDBox>
                            <MDBox mr={1}>
                                <Switch disabled checked={whatsapp} onChange={() => setWhatsapp(!whatsapp)} />
                            </MDBox>
                        </MDBox>
                    </MDBox>
                    <MDBox m={1} mt={3}>
                        <MDButton variant="gradient" color="light" fullWidth size="small" type="submit">
                            Ekle
                        </MDButton>
                    </MDBox>
                </MDBox>
            </form>
        </Card>
    );
}
