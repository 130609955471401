// Libraries
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';

// Layout
import ProjectLayout from 'layouts/projects/Pages/SingleProject/ProjectLayout';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// @mui material components
import Grid from '@mui/material/Grid';

// Project Pages And Components
import DemandPage from 'layouts/projects/Pages/SingleProject/DemandPage';
import SettingsPage from 'layouts/projects/Pages/SingleProject/SettingsPage';
import OfferPage from 'layouts/projects/Pages/SingleProject/OfferPage';
import OfferHistoryPage from 'layouts/projects/Pages/SingleProject/OfferHistoryPage';

import MainPage from 'layouts/projects/Pages/SingleProject/MainPage';
import AaroLoadingScreen from 'components/Loading';
import Chat from 'components/General/Chat';
import FileView from 'components/General/File';
import FileViewGoogleDrive from 'components/General/File/FileViewGoogleDrive';
import TaskListModalView from 'components/General/Task/Views/TaskList/ModalView';

import Activity from 'components/General/Activity/index.js';

import ProjectStatus from 'layouts/projects/Components/ProjectStatus';
// API
import { getProject } from 'services/api/project';
import { config } from 'Constants';
import { Divider } from '@mui/material';

import ProjectTags from 'layouts/projects/Components/ProjectTags/index.js';

const fetchProject = async (id) => {
    const response = await getProject(id)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};
const {
    PROJECT_LOSE_GROUP_ID,
    PROJECT_WIN_GROUP_ID,
    PROJECT_SOURCE_GROUP_ID,
    PROJECT_WINNER_GROUP_ID,
    PROJECT_TAGS_GROUP_ID,
} = config.fixedID;
const { kazanildi, kaybedildi } = config.statusID;

function GeneralPage() {
    const { id } = useParams();
    const [value, setValue] = useState(0);
    const [updateFiles, setUpdateFiles] = useState(0);
    const [shrink, setShrink] = useState(true);

    const { data, isLoading, error } = useQuery(['fetchProject', id], () => fetchProject(id));

    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    const isWin = data?.status?.tag?._id === kazanildi ? true : false;
    const isLose = data?.status?.tag?._id === kaybedildi ? true : false;
    const googleDriveFolderId = data?.googleDriveFolders?.main;

    const handlePageChanges = (pageNumber) => {
        switch (pageNumber) {
            case 0:
                return (
                    <MainPage project={data} relatedID={id} updateFiles={updateFiles} setUpdateFiles={setUpdateFiles} />
                );
            case 1:
                return <DemandPage project={data} />;
            // case 2:
            // 	return <Kanban project={data} />;
            case 2:
                return (
                    <MDBox mt={4}>
                        <OfferPage project={data} setValue={setValue} />
                    </MDBox>
                );
            case 3:
                return <OfferHistoryPage project={data} />;
            case 4:
                return <SettingsPage project={data} />;
            default:
                return <p>asdas</p>;
        }
    };

    return (
        <ProjectLayout setValue={setValue} setShrink={setShrink} shrink={shrink}>
            <MDBox mt={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={shrink ? 9 : 12}>
                        <MDBox mb={3}>{handlePageChanges(value)}</MDBox>
                    </Grid>
                    <Grid item xs={12} md={12} lg={3}>
                        <MDBox mb={3} mt={4}>
                            <ProjectStatus project={data} />
                        </MDBox>

                        <MDBox mb={3}>
                            <Chat relatedID={id} source="projects" />
                        </MDBox>
                        <MDBox mb={3}>
                            <FileViewGoogleDrive folderId={googleDriveFolderId} refreshTrigger={updateFiles} />
                        </MDBox>

                        {data?.taskList && (
                            <MDBox mb={3} bgColor="white" variant="gradient" borderRadius="lg" shadow="lg" opacity={1}>
                                <TaskListModalView taskListID={data?.taskList} taskID={null} />
                            </MDBox>
                        )}
                        <MDBox
                            mb={3}
                            bgColor="white"
                            display="grid"
                            alignItems="center"
                            color="white"
                            shadow="md"
                            borderRadius="lg"
                            variant="gradient"
                            p={1}
                        >
                            {isLose ? (
                                <>
                                    {' '}
                                    <ProjectTags
                                        project={data}
                                        tagLabel={'Neden Kaybedildi'}
                                        tagGroupID={PROJECT_LOSE_GROUP_ID}
                                        multiple={true}
                                        dbKey={'lose'}
                                    />
                                    <Divider />
                                    <ProjectTags
                                        project={data}
                                        tagLabel={'Kazanan Kim?'}
                                        tagGroupID={PROJECT_WINNER_GROUP_ID}
                                        multiple={true}
                                        dbKey={'winner'}
                                    />
                                    <Divider />
                                </>
                            ) : null}
                            {isWin ? (
                                <>
                                    {' '}
                                    <ProjectTags
                                        project={data}
                                        tagLabel={'Nasıl Kazanıldı'}
                                        tagGroupID={PROJECT_WIN_GROUP_ID}
                                        multiple={true}
                                        dbKey={'win'}
                                    />
                                    <Divider />
                                </>
                            ) : null}
                            <ProjectTags
                                project={data}
                                tagLabel={'Nasıl Geldi'}
                                tagGroupID={PROJECT_SOURCE_GROUP_ID}
                                multiple={false}
                                dbKey={'source'}
                            />

                            <Divider />
                            <ProjectTags
                                project={data}
                                tagLabel={'Proje Etiketleri'}
                                tagGroupID={PROJECT_TAGS_GROUP_ID}
                                multiple={true}
                                dbKey={'tags'}
                            />

                            <Divider />
                        </MDBox>

                        <MDBox mb={3}>
                            <FileView
                                relatedID={id}
                                updateFiles={updateFiles}
                                setUpdateFiles={setUpdateFiles}
                                source="projects"
                            />
                        </MDBox>
                        <MDBox mb={3}>
                            <Activity relatedID={id} source="projects" startingEntryNumber={5} />
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </ProjectLayout>
    );
}

export default GeneralPage;
