import React from 'react';
import { useQueryClient } from 'react-query';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import { updateProject } from 'services/api/project';
// @mui material components
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDButton from 'components/MDButton';

// Style'ları ayrı bir dosyaya taşıyabiliriz, ama şimdilik burada tutuyoruz
const useStyles = makeStyles({
    avatar: {},
    profilePicture: {
        color: 'transparent',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        textAlign: 'center',
        textIndent: '10000px',
    },
});

// Responsible Dialog bileşeni
function ResponsibleDialog({ onClose, open, allResponsibles, projectID, users, setOpen }) {
    const queryClient = useQueryClient();
    const classes = useStyles();

    const handleUpdateResponsibles = (user) => {
        if (!user?._id) return;

        const currentResponsibles = allResponsibles?.filter(Boolean)?.map((responsible) => responsible._id) || [];
        const isExistingResponsible = currentResponsibles.includes(user._id);
        const newResponsibles = isExistingResponsible
            ? currentResponsibles.filter((id) => id !== user._id)
            : [...currentResponsibles, user._id];

        setOpen(false);

        updateProject(projectID, { responsibles: newResponsibles })
            .then(() => {
                queryClient.invalidateQueries(['fetchProject', projectID]);
            })
            .catch((error) => {
                console.error('Failed to update responsibles:', error);
                // Burada bir hata bildirimi eklenebilir
            });
    };

    const renderUserAvatar = (user) => {
        if (!user)
            return (
                <Avatar className={classes.avatar}>
                    <PersonIcon />
                </Avatar>
            );

        return (
            <Avatar className={classes.avatar}>
                {user.profilePicture ? (
                    <img src={user.profilePicture} alt={user.name || 'User'} className={classes.profilePicture} />
                ) : (
                    <PersonIcon />
                )}
            </Avatar>
        );
    };

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>Projeye sorumlu ekle</DialogTitle>
            <List>
                {(users || [])
                    .filter((user) => user && user._id)
                    .map((user) => (
                        <ListItem
                            button
                            onClick={() => handleUpdateResponsibles(user)}
                            key={user._id}
                            selected={allResponsibles?.some((el) => el?._id === user._id)}
                        >
                            <ListItemAvatar>{renderUserAvatar(user)}</ListItemAvatar>
                            <ListItemText primary={user?.name || 'İsimsiz Kullanıcı'} />
                        </ListItem>
                    ))}
            </List>
        </Dialog>
    );
}

// Ana bileşen
function AddResponsible({ allResponsibles, projectID, users }) {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <MDButton variant="outlined" color="dark" iconOnly onClick={() => setOpen(true)}>
                <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
            </MDButton>
            <ResponsibleDialog
                open={open}
                setOpen={setOpen}
                onClose={() => setOpen(false)}
                allResponsibles={allResponsibles}
                projectID={projectID}
                users={users}
            />
        </>
    );
}

export default AddResponsible;
